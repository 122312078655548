<template>
  <div class="w-64 mb-8 lg:text-center">
    <h2 class="font-black uppercase text-3xl leading-tight mb-4">{{content.title}}</h2>
    <nuxt-link class="block uppercase my-2" v-for="(link, index) in content.children" :key="index" v-if="link.fields.internalLink" :to="link.fields.url" v-html="link.fields.title" :class="link.cssClass"/>
    <a class="block uppercase my-2" v-else :href="link.fields.url" :class="link.cssClass">
      {{link.fields.title}}
    </a>
  </div>
</template>

<script>
export default {
  props: [
    'content',
  ]
}
</script>