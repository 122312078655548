<template>
  <div class="pb-12 wrapper md:px-8 xl:px-0 event-experiences">
    <h5 class="opacity-0 section-heading" v-animate-onscroll="'animated fadeIn'">Included Experiences</h5>
    <div class="flex flex-wrap justify-center pt-4 -mb-2">
      <div v-for="card in content.children" class="opacity-0 experience-card" v-animate-onscroll="'animated fadeIn'" :key="card.index">
        <default-picture v-if="card.fields.icon" :defaultImg="card.fields.icon.fields.file.url" :description="card.fields.icon.fields.description" />
        <h5>{{card.fields.title}}</h5>
        <p v-if="card.fields.pdfLink"><a :href="card.fields.pdfLink.fields.file.url" target="_blank">{{card.fields.description}}</a></p>
        <p v-else>{{card.fields.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultPicture from '~/components/common/DefaultPicture'

export default {
  components: {
    DefaultPicture
  },
  props: [
    'content'
  ]
}
</script>
