<template>
  <div>
    <div v-if="content.backgroundImageDesktop" class="subheader w-full flex items-center justify-center">
      <div class="w-full content-container flex flex-col md:flex-row">
        <div class="w-full content flex flex-col items-center justify-center p-4" :class="content.cssClass">
          <h1 class="w-full m-4 md:mx-8 text-white" v-html="content.heading"/>
          <div v-if="content.body">
            <body-copy class="m-4 text-white md:m-8" :content="content.body.content" />
          </div>
        </div>
        <div class="w-full content img flex items-center justify-center" :style="`background-image: url(` + content.backgroundImageDesktop.fields.file.url + `);`"/>
      </div>
    </div>
    <div v-else class="subheader w-full flex flex-col items-center justify-center" :class="content.cssClass">
      <div class="w-full content-container ">
        <div class="content-short flex w-full flex-col items-center justify-center p-4" :class="content.cssClass">
          <h1 class="mt-4 mx-4 md:mx-8 text-white" v-html="content.heading"/>
            <body-copy class="m-4 w-full md:w-2/3 text-white md:m-8" :content="content.body.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'

  export default {
    components: {
      BodyCopy
    },
    props: [
      'content',
    ]
  }
</script>

<style lang="scss" scoped>
  @import '~/assets/scss/_variables.scss';

  div {
    position: relative;
    z-index: 10;
    .subheader {
      padding-top: 240px;
      flex-direction: column;
      @media (min-width: $sm) {
        padding-top: 400px;
      } 
        
      @media (min-width: $md) {
        flex-direction: row;
        padding-top: 575px;
      }
      @media (min-width: $xl) {
        padding-top: 650px;
      }

      .pink-orange {
        background: transparent;
        background-image: url('https://images.ctfassets.net/3dar4x4x74wk/3WbpCThhCB0G7CGEODvnAR/78be9a0c6ef778034896a3717968c587/PinkOrange.jpg') !important;
      }

      .black {
        background: #1F1F1E;
      }
      .content-container  {
        min-height: 310px;
        @media (min-width: $md) {
          min-height: 375px;
        }

        @media (min-width: $xl) {
          min-height: 550px;
        }
        // .img {
        //   min-height: 310px;
        //   @media (min-width: $md) {
        //     min-height: 375px;
        //   }
        // }
        .content, .content-short {
          width: 100%;
          min-height: 310px;
          background-size: cover;
          background-position: center;

          @media (min-width: $md) {  
            width: 50%;
            min-height: 375px;

            &.content-short {
              width: 100%;
            }
          }

          @media (min-width: $xl) { 
            min-height: 550px;
          }
          h1 {
            font-family: "urw-din-condensed";
            text-transform: uppercase;
            font-weight: bolder;
            text-align: center;
            font-size: 58px;

            @media (max-width: 1250px) {
              font-size: 50px;
            }

            @media (max-width: 940px) {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
</style>
