<template>
  <div class="card-slider opacity-0" v-animate-onscroll="'animated fadeIn'">

    <h2 class="px-3">{{content.heading}}</h2>
    <h3 class="px-3" v-if="content.subheading">{{content.subheading.content[0].content[0].value}}</h3>
 
    <slider :arrows="true" v-bind="settings" @afterChange="handleChange">

      <template #prevArrow>
        <img src="~/assets/img/icons/caret-left-purple.svg" alt="Prev">
      </template>

      <template #nextArrow>
        <img src="~/assets/img/icons/caret-right-purple.svg" alt="Next">
      </template>

      <div class="slider-card opacity-0" v-for="(vrPackage, index) in content.packages" :key="index" v-animate-onscroll="'animated fadeIn'">
        <div v-if="index == 0" class="label">Best Value</div>
        <div v-if="index == 1"  class="label">Fan-Favorite</div>
        <b>{{vrPackage.fields.title}}</b>
        <p>{{vrPackage.fields.body.content[1].content[0].value}}</p>
        <img :src="vrPackage.fields.body.content[0].data.target.fields.file.url" :alt="vrPackage.fields.title"/>
        <nuxt-link :to="vrPackage.fields.link.fields.url" class="pulse">{{vrPackage.fields.link.fields.title}}</nuxt-link>
      </div>

    </slider>

    <div class="text-center mt-5 sm:mt-8">
      <nuxt-link class="sm:mt-3 cta button purple pulse" :to="content.link.fields.url">
        {{content.link.fields.title}}
      </nuxt-link>
    </div>

    <div class="legal px-3">
      {{content.legal}}
    </div>
  </div>
</template>

<script>
  import Slider from 'vue-slick-carousel'

  export default {
    components: {
      Slider
    },
    props: [
      'content',
    ],
    data: function() {
      return {
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          centerMode: true,
          responsive: [
            {
              breakpoint: 1090,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 840,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
        }
      }
    },
    methods: {
      handleChange() {
        for(var i = 0; i < document.querySelectorAll('.slick-slide a').length; i++) {
          let x = document.querySelectorAll('.slick-slide a')[i].getBoundingClientRect().x
          if(x < window.innerWidth && x > 0) {
            document.querySelectorAll('.slick-slide a')[i].tabIndex = 0
          } else {
            document.querySelectorAll('.slick-slide a')[i].tabIndex = -1
          }
        }
      }
    }
  }
</script>