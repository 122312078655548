<template>
  <section class="headings wrapper mx-auto px-2 md:px-8 xl:px-0 pb-5">
    <h2 class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.title}}</h2>
    <h3 class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" v-if="content.heading">{{content.heading}}</h3>
  </section>
</template>

<script>
export default {
  props: [
    'content'
  ],
}
</script>