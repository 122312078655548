<template>
  <section class="wrapper mx-auto px-3 md:px-8 xl:px-0">
    <h3 class="section-heading magenta mt-16 opacity-0 w-auto" v-animate-onscroll="'animated fadeIn'">What others are saying</h3>
    <div class="feedback-slider opacity-0" v-animate-onscroll="'animated fadeIn'" @afterChange="handleChange">
      <slider v-bind="settings">

        <template #prevArrow>
          <button class="pulse magenta backward">Prev</button>
        </template>

        <template #nextArrow>
          <button class="pulse magenta forward">Next</button>
        </template>

        <div v-for="s in content.children" :key="s.index" class="relative outline-none">
          <img class="primary opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" :src="s.fields.image.fields.file.url" :alt="s.fields.image.fields.description">
          <div class="review opacity-0 delay-300" v-animate-onscroll="'animated fadeIn'">
            <h4>{{ s.fields.name }}</h4>
            <h5>{{ s.fields.subheading }}</h5>
            <div class="flex justify-between">
              <span>"</span>
              <i>{{ s.fields.quote }}</i>
              <span>"</span>
            </div>
          </div>
        </div>

      </slider>
    </div>
  </section>
</template>

<script>
  import Slider from 'vue-slick-carousel'
  import $ from 'jquery'

  export default {
    components: { Slider },
    props: [
      'content'
    ],
    data: function() {
      return {
        settings: {
          autoplay: true,
          autoplaySpeed: 4000,
          arrows: true,
          adaptiveHeight: true,
          onInit: function () {
            $(window).resize()
          },
        }
      }
    },
    mounted() {
      $(document).ready(function(){
         $(".slick-slide").each(function() { 
             let height = $(this).find('.review').outerHeight()+137
             $(this).css('min-height',height+'px')
         })
      })
    },
    methods: {
      handleChange() {
        for(var i = 0; i < document.querySelectorAll('.slick-slide a').length; i++) {
          let x = document.querySelectorAll('.slick-slide a')[i].getBoundingClientRect().x
          if(x < window.innerWidth && x > 0) {
            document.querySelectorAll('.slick-slide a')[i].tabIndex = 0
          } else {
            document.querySelectorAll('.slick-slide a')[i].tabIndex = -1
          }
        }
      }
    }
  }

</script>

<style lang="scss">
.section-heading.w-auto {
  width: auto;
}

.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-track{
    display: flex;
     align-items: stretch; 
     justify-content: center;
    .slick-slide{
        height: auto;       
    }
}
</style>