<template>
  <div class="article-card relative mx-1 sm:mx-2 opacity-0" :class="cssClass" v-animate-onscroll="'animated fadeIn'">
    <div class="article-card-date opacity-0" v-animate-onscroll="'animated fadeIn'" v-if="date">{{dateMonth}}<br/>{{dateDay}}</div>
    <img class="article-card-photo" :src="linkImage" :alt="linkImageAlt"/>
    <div class="article-card-body text-center p-2 sm:p-4 pb-12">
      <h4 class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{title}}</h4>
      <p class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{blurb}}</p>
      <nuxt-link :to="href" class="opacity-0" v-animate-onscroll="'animated zoomIn'">
        <button class="pulse">{{btnText}}</button>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DefaultPicture from '~/components/common/DefaultPicture'

export default {
  components: {
    DefaultPicture
  },
  props: {
    title: {
      default: 'Lorem ipsum dolor sit'
    },
    blurb: {
      default: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis a rutrum dolor, quis feugiat erat. Quisque ullamcorper ex eu hendrerit varius.'
    },
    btnText: '',
    href: {
      default: 'Learn More'
    },
    date: '',
    linkImage: '',
    linkImageAlt: '',
    cssClass: ''
  },
  computed: {
    dateMonth() {
      const d = new Date(this.date)
      return moment(d).format('MMM')
    },
    dateDay() {
      const d = new Date(this.date)
      return moment(d).format('D')
    }
  }
}
</script>