<template>
  <div class="event-subcategory" :class="content.cssClass">
    <img class="w-full" :src="content.image.fields.file.url+'?w=375'" :alt="content.image.fields.description" />
    <h3 class="font-bold text-2xl uppercase text-center" v-html="content.title" />
    <p v-html="content.tagline" />
    <div v-if="content.link && content.link.fields">
      <nuxt-link class="button pulse border-purple border-2" v-if="content.link.fields.internalLink" :to="content.link.fields.url">
        {{ content.link.fields.title }}
      </nuxt-link>
      <a class="button pulse border-purple border-2" v-else :href="content.link.fields.url" target="_blank" >
        {{ content.link.fields.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>