<template>
  <section class="wrapper mx-auto px-3 md:px-8 xl:px-0 mb-12">
    <h3 class="section-heading purple mt-16 opacity-0 w-auto" v-animate-onscroll="'animated fadeIn'">{{content.heading}}</h3>
    <div class="feedback opacity-0" v-animate-onscroll="'animated fadeIn'">
      <div v-for="testimonial in content.children" :key="testimonial.index" class="testimonial opacity-0 delay-300" v-animate-onscroll="'animated fadeIn'">
        <div>
          <blockquote>
            "{{ testimonial.fields.quote }}"
          </blockquote>
          <b>{{ testimonial.fields.name }}</b>
          <p v-if="testimonial.fields.organization">{{ testimonial.fields.organization }}</p>
          <p>{{ testimonial.fields.location }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: [
      'content'
    ],
  }
</script>