<template>
  <div class="experience-item">
    <img class="w-full" :src="content.cardMobile.fields.file.url" :alt="content.title" />
    <p>{{content.description}}</p>
    <nuxt-link :to="getLink(content.slug)" :title="content.title" class="button pulse">
      Learn More
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: [
    'content'
  ],
  methods: {
    getLink(slug) {
      if (slug === 'leagues') {
        return '/the-leagues'
      } else {
        return `/experiences/${slug}/`
      }
    }
   }
}
</script>