<template>
  <section class="safety-icons wrapper mx-auto px-3 md:px-8 xl:px-0">
    <h3 class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.body.content[0].content[0].value}}</h3>
    <div class="flex flex-wrap justify-center">
      <div v-for="child in content.children" class="safety-icon opacity-0" v-animate-onscroll="'animated fadeIn'">
        <img :src="child.fields.body.content[0].data.target.fields.file.url" :alt="child.fields.body.content[0].data.target.fields.description"/>
        <b>{{child.fields.title}}</b>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>