<template>
  <div :class="containerClass || 'inline-block'">
    <nuxt-link v-if="internal" :to="url" v-html="title" :class="`button ${color}`" />
    <a v-else :href="url" v-html="title" :class="`button ${color}`" />
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'url',
    'color',
    'internal',
    'containerClass'
  ]
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.pc-birthday {
  .feature-slide-text {
    div {
      .pulse {
        a {
          background: $blue;
          color: #fff;
        }
      }
    }
  }
}

.luck-birthday {
  .feature-slide-text {
    div {
      .pulse {
        a {
          background: $purple;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}
</style>