var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative hero",class:_vm.content.cssClass},[(_vm.content.backgroundDesktop.fields.file.contentType == 'video/mp4')?_c('video-background',{attrs:{"src":_vm.content.backgroundDesktop.fields.file.url,"sources":[
      {src: _vm.content.backgroundMobile.fields.file.url, res: 640, autoplay: true}
    ]}}):_c('default-picture',{attrs:{"sizes":[
      {
        url: _vm.bgMobile,
        break: 640
      }
    ],"defaultImg":_vm.bgDesktop,"description":_vm.descriptionMobile}}),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 flex items-center justify-center p-4"},[_c('h1',{staticClass:"animated fadeIn lockup",class:_vm.content.label ? 'label' : ''},[_vm._v("\n      "+_vm._s(_vm.content.label)+"\n      "),(_vm.imageTextMobile)?_c('default-picture',{attrs:{"sizes":[
          {
            url: _vm.imageTextMobile,
            break: 640
          }
        ],"defaultImg":_vm.imageTextDesktop,"description":_vm.imageTextMobileDescription,"cssClass":("transform " + (this.$route.name.includes('careers') ? 'scale-75 md:scale-50 origin-center' : 'origin-left'))}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }