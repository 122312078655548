<template>
  <div class="wrapper mx-auto">
    <div class="image-with-text">
      <img class="opacity-0" v-animate-onscroll="'animated fadeIn'" data-object-fit="cover" :data-src="content.backgroundImageDesktop.fields.file.url" :src="content.backgroundImageDesktop.fields.file.url" :alt="content.backgroundImageDesktop.fields.description"/>
      <div>
        <h3 class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" v-html="content.heading"/>
        <RichTextRenderer class="opacity-0 delay-400" v-animate-onscroll="'animated fadeIn'" v-if="content.body" :document="content.body" />
      </div>
    </div>
  </div>
</template>

<script>
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
  components: {
    RichTextRenderer,
  },
  props: [
    'content'
  ]
}
</script>

<style lang="scss">
  @import '../../assets/scss/_variables';

  .image-with-text {
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    justify-content: center;
    padding: 90px 0 140px;
    @media (max-width: $sm) {
      padding: 0 0 70px;
    }
    img {
      @media (max-width: $sm) {
        margin-bottom: 40px;
      }
    }
    > div {
      width: 523px;
      padding-left: 70px;
      max-width: 100%;
      @media (max-width: $sm) {
        padding: 0 25px;
      }
      ul {
        list-style: disc;
        transform: translateX(20px);
      }
      h3 {
        position: relative;
        height: 50px;
        width: 100%;
        max-width: 280px;
        background: $purple;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 36px;
        display: table;
        padding: 10px;
        width: 360px;
        line-height: 1.2;
        margin-bottom: 30px;
        max-width: 100%;
        @media (max-width: $sm) {
          width: auto;
          font-size: 28px;
          margin-bottom: 25px;
        }
      }
      p {
        line-height: 2;
        font-size: 16px;
        a {
          color: $purple;
        }
      }
    }
  }
</style>