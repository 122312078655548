<template>
  <section class="play-smart-play-safe">
    <div class="wrapper mx-auto px-3 md:px-8 xl:px-0">
      <img :src="content.backgroundImageDesktop.fields.file.url" alt="Play Smart Play Safe" class="opacity-0" v-animate-onscroll="'animated fadeIn'"/>
      <h2 v-html="content.heading"  class="opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'"/>
      <div class="mx-auto flex flex-wrap opacity-0 delay-200 justify-center" v-animate-onscroll="'animated fadeIn'">
        <div v-for="(child, i) in content.children" :key="i" class="icon-shell">
          <img :src="child.fields.icon.fields.file.url" :alt="child.fields.icon.fields.description" />
          <h5 v-html="child.fields.text"/>
        </div>
      </div>
    </div>
    <!-- <pre v-html="content.cta" /> -->
    <span class="pulse block"><app-link class="button inline-flex opacity-0" v-animate-onscroll="'animated fadeIn'" :content="content.cta[0]"/></span>
  </section>
</template>

<script>
import AppLink from '~/components/common/AppLink'

export default {
  components: {
    AppLink
  },
  props: [
    'content'
  ]
}
</script>