<template>
  <div class="careers-slider card-slider relative z-10" :class="content.cssClass">

    <h1 class="text-2xl text-center" v-html="content.heading"/>
 
    <slider class="text-center mx-auto w-full" :arrows="true" v-bind="settings" @afterChange="handleChange">

      <template #prevArrow>
        <img src="~/assets/img/icons/left-arrow-outline-purple.svg" alt="Prev">
      </template>

      <template #nextArrow>
        <img src="~/assets/img/icons/right-arrow-outline-purple.svg" alt="Next">
      </template>

      <div class="careers-card-slider" v-for="c in content.children" :key="c.index">
        <div class="img-con" :style="`background-image: url(` + c.fields.image.fields.file.url + `);`"/>
        <h1 class="text-xl md:text-2xl uppercase text-left my-4 m-auto w-full md:w-2/3" v-html="c.fields.title"/>
        <body-copy v-if="c.fields.body" class="text-left m-auto w-full md:w-2/3" :content="c.fields.body.content" />
      </div>

    </slider>
  </div>
</template>

<script>
  import Slider from 'vue-slick-carousel'
  import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    Slider,
    BodyCopy
  },
  props: [
    'content',
  ],
  data() {
    return {
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              centerMode: false,
            }
          },
        ]
      }
    }
  },
  mounted() {
    console.log(this.content)
  },
  computed: {
    lockup() {
      if (this.content.body) {
        return this.content.body.content[0].data.target.fields.file.url
      }
    },
  },
  methods: {
    handleChange() {
      for(var i = 0; i < document.querySelectorAll('.slick-slide a').length; i++) {
        let x = document.querySelectorAll('.slick-slide a')[i].getBoundingClientRect().x
        if(x < window.innerWidth && x > 0) {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = 0
        } else {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = -1
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.careers-slider {
  padding: 25px 0;
  background: #FAFAFA;

  @media (min-width: $md) {
    padding: 75px;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  .slick-slider {
    .slick-list {
      @media (max-width: $md) {
        padding: 0!important;
      }

      .slick-track {
        .slick-slide {
          width: 100%!important;
          max-height: 1100px;
          overflow: scroll;

          @media (max-width: $md) {
            min-width: 100vw!important;
          }
        }
      }
    }
  }

  .careers-card-slider {
    margin-top: 25px;
    .img-con {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: auto;
      width: 100%;
      height: 600px;

      @media (min-width: $md) {
        width: 66%;
        background-size: contain;
      }
    }
  }
}

.growth-slider {
  h1 {
    display: none;
  }
  
  .careers-card-slider {
    .img-con {
      height: 200px;
      background-size: contain;
      @media (min-width: $md) {
        height: 600px;
      }
    }
  }
}
</style>