<template>
  <div class="article-card relative mx-1 sm:mx-2 opacity-0" :class="cssClass" v-animate-onscroll="'animated fadeIn'">
    <div class="article-card-date" v-if="date">{{dateMonth}}<br/>{{dateDay}}</div>
    <img class="article-card-photo" :src="linkImage+'?w=350'" :alt="linkImageAlt" :data-src="linkImage+'?w=350'" data-object-fit="cover"/>
    <div class="article-card-body text-center p-2 sm:p-4 pb-12">
      <h4>{{title}}</h4>
      <p>{{blurb}}</p>
      <nuxt-link :to="href">
        <button class="pulse">{{btnText}}</button>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DefaultPicture from '~/components/common/DefaultPicture'

export default {
  components: {
    DefaultPicture
  },
  props: {
    title: {
      default: 'Lorem ipsum dolor sit'
    },
    blurb: {
      default: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis a rutrum dolor, quis feugiat erat. Quisque ullamcorper ex eu hendrerit varius.'
    },
    btnText: '',
    href: {
      default: 'Learn More'
    },
    date: '',
    linkImage: '',
    linkImageAlt: '',
    cssClass: ''
  },
  computed: {
    dateMonth() {
      const d = new Date(this.date)
      return moment(d).format('MMM')
    },
    dateDay() {
      const d = new Date(this.date)
      return moment(d).format('D')
    }
  },
}
</script>

<style lang="scss">
.article-card-body {
  min-height: calc(100% - 8.8rem)
}
</style>