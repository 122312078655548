<template>
  <div class="triple-column" :class="content.cssClass">
    <p class="text-center" v-if="columnOne" v-html="columnOne" />
    <hr />
    <div>
      <hr v-if="columnTwo" />
      <BodyCopy class="text text-center" v-if="columnTwo" :content="columnTwo" />
      <hr v-if="columnThree" />
      <BodyCopy class="text text-center" v-if="columnThree" :content="columnThree" />
    </div>
  </div>
</template>

<script>
import BodyCopy from './BodyCopy.vue'

export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BodyCopy
  },
  computed: {
    columnOne() {
      return this.content.children[0].fields.heading || null
    },
    columnTwo() {
      return this.content.children[0].fields.detailsColumnOne.content || null
    },
    columnThree() {
      return this.content.children[0].fields.detailsColumnTwo.content|| null
    }
  }
}
</script>

<style lang="scss" scoped>

@import '~/assets/scss/_variables.scss';

.triple-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  max-width: 1000px;
  margin: auto;
  padding: 20px;

  @media (min-width: 900px) {
    flex-direction: row;
  }

  p {
    font-size: 32px;
    line-height: 110%;
    color: #2ad8b5;
    text-transform: uppercase;
    font-weight: 900;

    @media(min-width: $md) {
      width: 300px;
    }
  }

  .text {
    font-size: 16px;
    margin: unset!important;
    max-width: 300px;
    padding: 10px;
  }

  > div:first-of-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @media (min-width: 600px) {
      flex-direction: row;
    }

    hr {
      width: 100%;
      height: 5px;
      background: $purple;

      @media (min-width: 600px) {
        height: 150px;
        width: 5px;
      }

      &:first-of-type {
        @media (max-width: 900px) {
          display: none;
        }

        @media (max-width: 600px) {
          display: unset;
        }
      }
    }
  }
}
</style>