<template>
  <div class="w-full max-w-5xl px-4 py-6 m-auto overflow-hidden transition-all duration-200 ease-in-out accordian md:py-16">
    <div class="w-full py-4 overflow-hidden transition-all duration-200 ease-in-out border-white accordian-item bg-purple border-b-1">
      <h3 class="mx-4 text-2xl font-bold text-white" v-html="content.heading"/>
    </div>
    <div v-for="faq in content.children" :key="faq.sys.id" class="w-full border-white accordian-item bg-purple border-b-1">
      <div class="flex items-center justify-between w-full py-4">
        <h3 @click="setActive(faq.sys.id)" class="mx-4 text-xl font-bold text-white cursor-pointer" v-html="faq.fields.title"/>
        <img @click="setActive(faq.sys.id)" class="w-4 h-auto mx-4 cursor-pointer" :class="isActive === faq.sys.id ? 'rotate-180' : ''" src="https://images.ctfassets.net/3dar4x4x74wk/21xsy558aIqZEfqqhVsETI/a436c8965b50971c063e54e0c80f6d07/arrow-down-white.svg" alt="expandable or contractoable item indicator white arrow">
      </div>
      <transition
        name="slide-in-up"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <div v-show="isActive === faq.sys.id" class="w-full overflow-hidden transition-all duration-200 ease-in-out bg-white border-2 item-body border-purple">
          <div class="px-4 py-8 break-words"><body-copy :content="faq.fields.body.content" /></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    BodyCopy
  },
  props: [
    'content',
  ],
  data() {
    return {
      isActive: null,
    }
  },
  methods: {
    setActive(id) {
      if (this.isActive === id) {
        this.isActive = null
      } else {
        this.isActive = id
      }
    },

    beforeEnter(el) {
      el.style.height = '0';
    },

    enter(el) {
      el.style.height = el.scrollHeight + 'px';
    },

    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px';
    },

    leave(el) {
      el.style.height = '0';
    },
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.accordian-item {
  border-bottom: 2px solid #fff;
  &:first-of-type {
    border-radius: 10px 10px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 10px 10px;
    border-bottom: none;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  img, .item-body {
    transition: all 500ms;
  }
}

.slide-in-up-enter-active, .slide-in-up-leave-active {
  @apply duration-200;
  @apply transition-all;
  @apply translate-y-0;
}

.slide-in-up-enter, .slide-in-up-leave-to {
  @apply opacity-0;
  transform: translateY(20px);
}
</style>
