<template>
  <div :class="content.cssClass" class="flex w-full mb-8 items-center justify-center" :style="`background-image: url(` + content.backgroundImageDesktop.fields.file.url + `);`">
    <div class="hero-container w-full flex items-center justify-between">
      <img class="mt-6 sm:m-0 logo" v-if="hasImage" :src="this.content.logo.fields.file.url" alt="Main Event Logo">
      <div class="img-wrapper flex items-center justify-center">
        <img :class="hasImage ? 'mt-4 sm:m-0' : 'mt-10 sm:m-0'" :src="content.backgroundImageMobile.fields.file.url" :alt="content.backgroundImageMobile.fields.title">
      </div>
      <div class="content-wrapper flex flex-col sm:flex-row items-center justify-center">
        <img class="mb-4" v-if="!hasImage" src="https://images.ctfassets.net/3dar4x4x74wk/cZZNUCdSENJnx4PZ6FLZs/7d534bbd01fc55c6392bc1618a192fae/logo.svg" alt="Main Event Logo">
        <body-copy class="address text-center uppercase text-white font-bold text-sm font-decimal tracking-widest mt-2" :content="content.body.content" />
      </div>
    </div>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    BodyCopy
  },
  props: [
    'content',
    // 'languageToggle'
  ],
  computed: {
    languageToggle() {
      return this.$store.state.languageToggle
    },
    hasImage() {
      if (this.content.logo && this.content.logo.fields.file.url) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.lost-pins-hero {
  padding: 80px 0 40px;
  background-position: bottom;
  background-size: cover;
  
  @media (min-width: $sm) {
    padding: 116px 0 58px;
  }

  @media (min-width: $lg) {
    min-height: 650px;
    padding: 174px 0 58px;
  }

  .hero-container {
    width: 100%;
    padding: 0 30px;
    flex-direction: column;

    .logo {
      max-width: 125px;

      @media (min-width: $md) {
        max-width: 225px;
        padding-bottom: 15px;
      }
    }

    .img-wrapper {
      width: 100%;
    }

    .content-wrapper {
      padding-top: 25px;
      width: 100%;

      img {
        width: 132px;
        height: auto;
        margin-right: 25px;
      }

      .address {
        margin-bottom: 0!important;
      }
    }
  }
}
</style>