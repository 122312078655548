<template>
  <div class="details-section items-center justify-center">
    <div class="flex flex-col" :class="content.cssClass">
      <h3 v-if="content.heading" class="mb-2 opacity-0" v-animate-onscroll="'animated fadeIn'" v-html="content.heading"/>
      <div class="flex flex-wrap items-start justify-around">
        <div v-for="child in content.children" :key="child.index" class="card flex flex-wrap items-center justify-center">
          <div class="top" :style=" `background-image: url(` + child.fields.image.fields.file.url + `);`"/>
          <div class="bottom flex flex-col items-center justify-center">
            <p class="date text-center text-white font-bold uppercase" v-html="child.fields.subheading"/>
            <h1 class="uppercase text-white font-bold text-2xl text-center" v-html="child.fields.heading"/>
            <p class="text-white font-bold text-center" v-html="child.fields.text"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.nco-opening {
  max-width: 350px;

  @media (min-width: $sm) {
    max-width: 675px;
  }

  @media (min-width: $lg) {
    max-width: 1150px;
  }

  h3 {
    color: $purple;
  }

  .card {
    margin-bottom: 25px;

    @media (min-width: $sm) {
      width: 47.5%;
    }

    @media (min-width: $lg) {
      width: 31%;
    }

    .top {
      height: 175px;
      background-size: cover;
      background-position: center;
    }

    .bottom {
      height: 285px;
      background: $purple;
      background-image: url('https://images.ctfassets.net/3dar4x4x74wk/1vcICrYk082V0ewiZaoHx8/3635ce895cf6af73036b138ba6c81003/Beaumont_web_BG_copy-01.svg');
      background-size: cover;
      background-position: center;
      position: relative;

      .date {
        width: auto;
        background: $purple;
        padding: 0 15px;
        position: absolute;
        top: 0;
      }

      p, h1 {
        width: 75%;
      }
    }
  }
}
</style>