<template>
  <div class="current-openings flex relative z-10 flex-col items-center justify-center p-8" :class="content.cssClass">
    <h1 class="mb-4" v-html="content.heading"/>
    <div class="w-full flex flex-col items-center justify-center" v-for="content in content.children" :key="content.index">
      <div class="w-full md:w-2/3 bg-white p-2 md:p-4 flex items-center justify-between">
        <h2 class="font-bold text-small md:text-base" v-html="content.fields.title"/>
        <app-link :content="content.fields.cta" class="pulse"/>
      </div>
      <div class="w-full md:w-2/3 p-2 md:p-4" v-for="c in content.fields.detailsColumnOne.content" :key="c.index">
        <h3 class="text-xs md:text-base" v-html="c.content[0].value"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppLink from '~/components/common/AppLink'

export default {
  components: {
    AppLink
  },
  props: [
    'content',
  ],
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.current-openings {
  padding: 50px;
  background-position: top;

  @media (min-width: $md) {
    padding: 75px;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  div {
    div {
      border-bottom: 1px solid #d3d3d3;
      &:nth-of-type(1) {
        border: 1px solid #d3d3d3;
        margin-top: 50px;
      }
      a {
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        color: white;
        background-color: $pink;
        padding: 10px 20px;
        border-radius: 5px;
      }
    }
  }
}
</style>