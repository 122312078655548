<template>
  <section class="relative opacity-0 mb-10 default" v-animate-onscroll="'animated fadeIn'" :class="content.cssClass">
    <default-picture v-if="defaultImage" :sizes="images" :defaultImg="defaultImage" :description="alt"/>
    <div class="video-wrapper" v-if="content.video">
      <video playsinline autoplay muted loop>
          <source :src="content.video.fields.file.url" type="video/mp4"/>
      </video>
    </div>
    <div v-if="content.body" :class="content.type" class="flex -mt-24 px-2 relative z-10 lg:absolute lg:inset-0 lg:mt-0 lg:px-0 lg:items-center lg:justify-end">
      <div :class="{'has-video': content.video}" class="feature-slide-text text-white text-center p-8 w-full max-w-lg opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'">
        <body-copy :content="content.body.content" class="opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'"/>
        <div class="opacity-0 delay-700" v-animate-onscroll="'animated fadeIn'">
          <default-button
            class="pulse"
            v-for="cta in content.cta"
            :key="cta.index"
            :url="cta.fields.url"
            :title="cta.fields.title"
            :internal="cta.fields.internalLink" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DefaultButton from './DefaultButton'
import DefaultPicture from './DefaultPicture'
import BodyCopy from './BodyCopy'

export default {
  components: {
    DefaultButton,
    DefaultPicture,
    BodyCopy,
  },
  props: [
    'content'
  ],
  computed: {
    images() {
      return this.content.backgroundImageMobile ? [
        {
          url: this.content.backgroundImageMobile.fields.file.url,
          break: 992
        }
      ] : []
    },
    defaultImage() {
      return this.content.backgroundImageDesktop ? this.content.backgroundImageDesktop.fields.file.url : ''
    },
    alt() {
      return this.content.backgroundImageMobile ? this.content.backgroundImageMobile.fields.description : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.red-holiday-snowflakes {
  .feature-slide-text {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/4JS0YZsJubrmRvWpbktHZp/c034f305d62ae63de2ee2924887ccb14/Box_with_Snowflakes.png');
  }
}

.pc-birthday {
  .feature-slide-text {
    background: #fff;

    div {
      p {
        color: #000;
      }
      .pulse {
        a {
          background: $blue;
          color: #fff;
        }
      }
    }
  }
}

.family-kitchen {
  .feature-slide-text {
    background: #314d56!important;
    background-image: none!important;

    div { 
      p, a {
        font-family: 'Kent4FPrinted'!important;
      }
      a {
        color: #314d56 !important;
      }
      p {
        font-weight: 400!important;
      }
    }
  }
}

.luck-birthday {
  .feature-slide-text {
    background: #3bb93b;

    div {
      p {
        color: #fff;
      }
      .pulse {
        a {
          background: $purple;
          color: #3bb93b;
        }
      }
    }
  }
}
</style>
