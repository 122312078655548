<template>
  <section class="safety-headings wrapper mx-auto px-3 md:px-8 xl:px-0">
    <h3>{{content.title}}</h3>
    <h4>{{content.body.content[0].content[0].value}}</h4>
  </section>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>