<template>
  <div class="app-features flex flex-wrap">
    <div v-for="(feature, index) in content.children" :key="index" class="feature w-auto opacity-0" v-animate-onscroll="'animated fadeIn'">
      <img :src="feature.fields.body.content[0].data.target.fields.file.url" :alt="feature.fields.body.content[0].data.target.fields.file.description"/>
      <h3>{{feature.fields.title}}</h3>
      <p>{{feature.fields.body.content[1].content[0].value}}</p>
    </div>
  </div>
</template>

<script>  
export default {
  props: [
    'content'
  ]
}
</script>