<template>
  <div class="details-section items-center justify-center">
    <div class="flex items-center justify-between" :class="content.cssClass">
      <div class="left flex flex-col items-center justify-center">
        <h3 class="text-center font-bold text-xl uppercase md:text-2xl" v-html="`${languageToggle === true ? 'Cuenta regresiva para nuestra gran inauguración' : 'Countdown To Our Grand Opening'}`"/>
        <div class="counter flex w-full items-center justify-between mt-4">
          <div class="flex flex-col w-full items-center justify-between">
            <p class="font-bold uppercase mb-4" v-html="`${languageToggle === true ? 'Días' : 'Days'}`" />
            <h2 class="font-bold text-3xl lg:text-5xl" id="days"/>
          </div>
          <div class="flex flex-col items-center justify-between">
            <p class="font-bold uppercase mb-4" v-html="`${languageToggle === true ? 'Horas' : 'Hours'}`" />
            <h2 class="font-bold text-3xl lg:text-5xl" id="hours"/>
          </div>
          <div class="flex flex-col items-center justify-between">
            <p class="font-bold uppercase mb-4" v-html="`${languageToggle === true ? 'Minutos' : 'Minutes'}`" />
            <h2 class="font-bold text-3xl lg:text-5xl" id="minutes"/>
          </div>
          <div class="flex flex-col items-center justify-between">
            <p class="font-bold uppercase mb-4" v-html="`${languageToggle === true ? 'Segundos' : 'Seconds'}`" />
            <h2 class="font-bold text-3xl lg:text-5xl" id="seconds"/>
          </div>
        </div>
      </div>
      <div class="right flex items-center justify-between">
        <div class="two flex flex-col items-center justify-center h-full">
          <div class="w-full" :style="`background-image: url(` + imageOne + `);`" />
          <div class="h-full w-full" :style="`background-image: url(` + imageTwo + `);`" />
        </div>
        <div class="one flex items-center justify-center h-full" :style="`background-image: url(` + imageThree + `);`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'content',
    // 'languageToggle'
  ],
  computed: {
    languageToggle() {
      return this.$store.state.languageToggle
    },
    nco() {
      return this.$route.params.slug
    },
    imageOne() {
      // if (this.nco != 'tomball') {
      //   return "https://images.ctfassets.net/3dar4x4x74wk/3S7z6ASjPecUPpMEA0IWO1/898d010ee9a4f9a0642d19a39f7da830/Main_Event_Austin_14.jpg"
      // } else {
      //   return "https://images.ctfassets.net/3dar4x4x74wk/4PhW4tKpdE1HU69AYsLHl0/c98c657828d068f9b9ef1bc60219d39c/Screen_Shot_2022-02-03_at_9.52.58_AM.png"
      // }

      return "https://images.ctfassets.net/3dar4x4x74wk/4PhW4tKpdE1HU69AYsLHl0/c98c657828d068f9b9ef1bc60219d39c/Screen_Shot_2022-02-03_at_9.52.58_AM.png"
    },
    imageTwo() {
      // if (this.nco != 'tomball') {
      //   return ""https://images.ctfassets.net/3dar4x4x74wk/2wrltAR60D2NTfuyQJvZjv/183f54e48599d75738827a365cb3950c/Main-Event-Grand-Prairie-Web_27.jpg""
      // } else {
      //   return "https://images.ctfassets.net/3dar4x4x74wk/6nFGhsuud8KRUIGQIWniwM/5793365d3b59080e0b26ae12adbb8bb1/Screen_Shot_2022-02-03_at_9.53.49_AM.png"
      // }

      return "https://images.ctfassets.net/3dar4x4x74wk/6nFGhsuud8KRUIGQIWniwM/5793365d3b59080e0b26ae12adbb8bb1/Screen_Shot_2022-02-03_at_9.53.49_AM.png"
    },
    imageThree() {
      // if (this.nco != 'tomball') {
      //   return "https://images.ctfassets.net/3dar4x4x74wk/4WAJVSapO25oFVpKErPyZ8/07fe564e81712a09262a510509951bb1/countdown-3.jpg"
      // } else {
      //   return "https://images.ctfassets.net/3dar4x4x74wk/6fsqzT5bgDOU5PTLDLAlW7/e78e598b67728b0c34b2b92c8a69de94/Screen_Shot_2022-02-03_at_9.53.13_AM.png"
      // }

      return "https://images.ctfassets.net/3dar4x4x74wk/3bOUeTHobbawxTlJnE5k9F/c37d46f075a7cb51446b78c91d6ab2e6/120620_MainEvent_KathyTran_B41A0221.jpg"
    },
  },
  methods: {
    getCountdown(e) {
      let countDownDate = new Date(e.heading).getTime()

      setInterval(function() {
        var now = new Date().getTime();
        
        var distance = countDownDate - now;
        
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (document.getElementById("days") !== null) {
          document.getElementById("days").innerHTML = days
        }
        if (document.getElementById("hours") !== null) {
          document.getElementById("hours").innerHTML = hours
        }
        if (document.getElementById("minutes") !== null) {
          document.getElementById("minutes").innerHTML = minutes
        }
        if (document.getElementById("seconds") !== null) {
          document.getElementById("seconds").innerHTML = seconds
        }
      }, 1000)
    }
  },
  mounted() {
    this.getCountdown(this.content)
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.countdown {
  max-width: 350px;
  flex-direction: column;

  @media (min-width: $sm) {
    max-width: 675px;
    flex-direction: row;
  }

  @media (min-width: $lg) {
    max-width: 1150px;
  }

  .left, .right {
    @media (min-width: $md) {
      width: 47.5%;
    }
    h3 {
      color: $purple;
      margin-bottom: 15px;
    }
    .counter {
      .flex {
        h2 {
          background-color: $purple;
          color: white;
          padding: 10px 20px;
          border-radius: 10px;


        }
      }
    }
    &.right {
      height: 375px;
      margin: 50px 0;

      @media (min-width: $lg) {
        height: 500px;
        margin: 0;
      }

      .two {
        width: 47.5%;
        div {
          background: $purple;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          &:nth-of-type(1) {
            margin-bottom: 4%;
          }
          &:nth-of-type(2) {
            background-size: 450px;
          }
        }
      }

      .one {
        width: 47.5%;
        background: $purple;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>