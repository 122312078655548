<template>
  <div :class="content.cssClass" class="flex w-full mb-8 items-center justify-center" :style="`background-image: url(` + content.backgroundImageDesktop.fields.file.url + `);`">
    <div class="nco-container w-full flex items-center justify-between">
      <div class="flex items-center justify-center">
        <img class="w-full mt-10 sm:m-0" :src="content.backgroundImageMobile.fields.file.url" :alt="content.backgroundImageMobile.fields.title">
      </div>
      <div class="flex flex-col items-center justify-center">
        <h4 v-if="content.heading" class="soon text-center uppercase text-white font-bold mb-4" v-html="content.heading"></h4>
        <h4 v-else class="soon text-center uppercase text-white font-bold mb-4" v-html="languageToggle ? 'Próximamente' : 'Coming Soon'"></h4>
        <img class="w-full" src="https://images.ctfassets.net/3dar4x4x74wk/cZZNUCdSENJnx4PZ6FLZs/7d534bbd01fc55c6392bc1618a192fae/logo.svg" alt="Main Event Logo">
        <!-- <h2 v-if="content.heading" class="text-2xl align-center uppercase text-white font-bold mb-4 lg:text-5xl" v-html="content.heading"/> -->
        <body-copy class="address text-center uppercase text-white font-bold md:text-lg xl:text-xl" :content="content.body.content" />
      </div>
    </div>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    BodyCopy
  },
  props: [
    'content',
    // 'languageToggle'
  ],
  computed: {
    languageToggle() {
      return this.$store.state.languageToggle
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.nco-hero {
  padding: 80px 0 40px;
  background-position: bottom;
  background-size: cover;
  
  @media (min-width: $sm) {
    padding: 116px 0 58px;
  }

  @media (min-width: $lg) {
    min-height: 750px;
    padding: 174px 0 58px;
  }

  .nco-container {
    max-width: 1200px;
    padding: 0 50px;
    flex-direction: column-reverse;

    @media (min-width: $md) {
      flex-direction: row;
    }

    .flex {
      width: 90%;

      &:last-of-type {
        @media (min-width: $md) {
          width: 45%;
        }

        @media (min-width: $lg) {
          width: 52.5%;
        }
      }

      @media (min-width: $md) {
        width: 35%;
      }

      @media (min-width: $lg) {
        width: 42.5%;
      }



      .soon {
        letter-spacing: 10px;
      }

      .address {
        margin-top: 20px;
      }

      .phone {
        margin-bottom: 0;
      }
    }
  }
}
</style>