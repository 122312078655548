<template>
  <div class="w-full flex flex-col items-center justify-center mx-auto" :class="content.cssClass">
    <div class="header flex items-center justify-center">
      <div class="line"/>
      <h3 v-if="content.heading" class="mb-2 uppercase font-extrabold" v-html="content.heading"/>
      <div class="line"/>
    </div>
    <div class="clues flex flex-wrap items-center justify-center">
      <div v-for="child in content.children" :key="child.index" class="card flex flex-wrap items-start justify-center" :style="`background-image: url(` + child.fields.image.fields.file.url + `);`" >
        <!-- <div v-if="child.fields.heading.toLowerCase() === 'coming soon'" class="coming-soon w-full flex flex-col items-center justify-center">
          <img :src="child.fields.image.fields.file.url" alt="Clue Coming Soon Top Image">
          <div class="bottom flex flex-col items-center justify-center">
            <img src="https://images.ctfassets.net/3dar4x4x74wk/2qlD7E91MO4CmeHcAGAo89/3728e88beeac7227814495bc591b08f1/coming-soon.svg" alt="Coming Soon">
            <div class="divider" />
            <p class="header text-center text-white font-extrabold font-decimal uppercase" v-html="child.fields.subheading"/>
            <p class="body text-white font-semibold text-center font-decimal" v-html="child.fields.text"/>
          </div>
        </div>
        <div v-else class="clue w-full">
          <img :src="child.fields.image.fields.file.url" alt="Clue Top Image">
          <div class="bottom flex flex-col items-center justify-center">
            <div class="clue-number flex items-center justify-center">
              <img src="https://images.ctfassets.net/3dar4x4x74wk/71fQYlEcsHATELD3PCfTPP/60d323dd40506caf4d9639fe719d8270/Asset_3.svg" alt="Clue Number">
              <h1 class="uppercase text-white font-bold text-2xl text-center font-pie" v-html="child.fields.heading"/>
            </div>
            <p class="header text-center text-white font-extrabold font-decimal uppercase" v-html="child.fields.subheading"/>
            <p class="body text-white font-semibold text-center font-decimal" v-html="child.fields.text"/>
          </div>
        </div> -->
      </div>
    </div>
    <img class="pins-chart w-full" :src="content.backgroundImageDesktop.fields.file.url"/>
  </div>
</template>

<script>
export default {
  props: ["content", /*"languageToggle"*/],
  computed: {
    languageToggle() {
      return this.$store.state.languageToggle
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.lost-pins-clues {
  padding: 30px;

  .header {
    width: 100%;
    margin: auto;
    max-width: 750px;

    .line {
      height: 2px;
      flex: 1;
      background-color: #553286;
    }

    h3 {
      color: #553286;
      padding: 0 20px;
      margin: 0;
      font-size: 24px;
    }
  }

  .clues {
    padding-top: 50px;
    width: 100%;
    max-width: 990px;
    .card {
      margin-bottom: 25px;
      width: 300px;
      height: 390px;
      // background: url('https://images.ctfassets.net/3dar4x4x74wk/YMVJWNlOvF1WSXAw4xnQE/fb80e1e58df8bc7ffd5bddd9ab1b1339/turqiose-gradient.png');
      background-size: cover;
      background-position: center;

      @media (min-width: 600px) {
        margin-right: 30px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .coming-soon, .clue {
        .bottom {
          .header {
            font-size: 18px;
            margin-bottom: 10px;
          }

          .body {
            font-size: 16px;
          }
        }
      }

      .coming-soon {
        width: 100%;
        height: 370px;
        margin: 15px;
        background: #003393;
        padding: 30px;

        img {
          width: 75%;
        }

        .bottom {
          width: 100%;

          img {
            width: 100%;
            padding: 30px 0 15px 0;
          }

          .divider {
            width: 100px;
            height: 3px;
            background: white;
            margin: 15px 0;
          }
        }
      }

      .clue {

        .bottom {
          padding: 30px;

          .clue-number {
            width: 100%;
            margin-bottom: 30px;

            img {
              width: 20%;
              margin-right: 15px;
            }

            h1 {
              font-size: 102px;
              line-height: 75px;
            }
          }
        }
      }
    }
  }

  .pins-chart {
    max-width: 750px;
    padding-top: 30px;
  }
}
</style>