<template>
  <div class="experience-list wrapper mx-auto px-2 md:px-8 xl:px-0">
    <h2 class="text-5xl text-center font-bold uppercase opacity-0" v-animate-onscroll="'animated fadeIn'" v-if="content.heading" v-html="content.heading"/>
    <h3 v-if="content.body" class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.body.content[0].content[0].value}}</h3>
    <div class="flex flex-wrap experience-item-wrapper">
      <div class="w-full sm:w-1/2 md:w-1/3 opacity-0" v-animate-onscroll="'animated fadeIn'" v-for="event in content.children" :key="event.index">
        <experience-item :content="event.fields" />
      </div>
    </div>
  </div>
</template>

<script>
import ExperienceItem from './ExperienceItem'

export default {
  components: {
    ExperienceItem
  },
  props: [
    'content'
  ]
}
</script>