<template>
    <div class="package-details flex-col md:flex-row">
        <div class="flex-1 md:flex-none md:pr-10 text-center">
            <img v-if="imageDesktop" :src="imageDesktop.fields.file.url" :alt="imageDesktop.fields.description" class="hidden md:block m-auto md:m-0" />
            <img v-if="imageMobile" :src="imageMobile.fields.file.url" :alt="imageMobile.fields.description" class="md:hidden m-auto" />
            <nuxt-link class="mb-8 md:mb-0 button magenta pulse" to="/book/virtual-experiences/">
              Book Now
            </nuxt-link>
        </div>
        <div class="flex-1">
            <div class="package-details-title text-center md:text-left">{{title}}</div>
            <div class="py-4 border-b border-t flex-col sm:flex-row flex flex-wrap">
                <div class="package-details-price mr-0 sm:mr-2 md:mr-4"><span>Starting at</span> <span><sup>$</sup>{{dollars(price)}}<sup>{{cents(price)}}</sup></span> <span>per guest</span></div>
                <div class="package-details-description mt-4 sm:mt-2">{{description}}</div>
            </div>
            <div class="package-details-includes">
                <div class="package-details-includes-header">Package Includes</div>
                <div class="package-details-includes-content" v-for="inc in includes.content" :key="inc.index">
                  <div v-if="inc.nodeType == 'unordered-list'">
                    <ul>
                      <li v-for="itm in inc.content" :key="itm.index" v-html="itm.content[0].content[0].value" />
                    </ul>
                  </div>
                  <div v-if="inc.nodeType == 'paragraph'">
                    <p v-html="inc.content[0].value" />
                  </div>
                </div>
            </div>
        </div>
        <nuxt-link class="mx-auto mt-2 sm:mt-6 button button-mobile purple" to="/book/virtual-experiences/">
          Book Now
        </nuxt-link>
    </div>
</template>

<script>
export default {
  props: [
    'title',
    'price',
    'description',
    'includes',
    'imageDesktop',
    'imageMobile',
  ],
  methods: {
    dollars(price) {
      return Math.floor(price)
    },
    cents: function(price) {
      if (Math.round(price * 100 % 100) == 0) {
        return '00'
      } else {
        return Math.round(price * 100 % 100)
      }
    }
  },
  data: function() {
    return {
    }
  }
}
</script>

<style lang="scss">
</style>