<template>
  <section :class="content.cssClass" class="relative opacity-0 mb-10 links-section" v-animate-onscroll="'animated fadeIn'">
    <default-picture v-if="defaultImage" :sizes="images" :defaultImg="defaultImage" :description="alt"/>
    <div class="video-wrapper" v-if="content.video">
      <video playsinline autoplay muted loop>
          <source :src="content.video.fields.file.url" type="video/mp4"/>
      </video>
    </div>
    <div class="links">
      <nuxt-link v-for="(link, index) in content.children" :key="index" class="button magenta pulse" :to="link.fields.url">{{link.fields.title}}</nuxt-link>
    </div>
  </section>
</template>

<script>
import DefaultButton from './DefaultButton'
import DefaultPicture from './DefaultPicture'
import BodyCopy from './BodyCopy'

export default {
  components: {
    DefaultButton,
    DefaultPicture,
    BodyCopy,
  },
  props: [
    'content'
  ],
  computed: {
    images() {
      return this.content.backgroundImageMobile ? [
        {
          url: this.content.backgroundImageMobile.fields.file.url,
          break: 992
        }
      ] : []
    },
    defaultImage() {
      return this.content.backgroundImageDesktop ? this.content.backgroundImageDesktop.fields.file.url : ''
    },
    alt() {
      return this.content.backgroundImageMobile ? this.content.backgroundImageMobile.fields.description : ''
    }
  }
}
</script>