<template>
  <div class="flex flex-wrap">
    <nav class="menu-nav mr-10 xl:mr-12 px-2 sm:px-0">
      <button v-for="(category, index) in content.children" :key="category.index" @click="showMenu($event, category.fields.id)" :class="{'active' : category.fields.id == 'cocktails'}" class="opacity-0 animated fadeIn">{{category.fields.title}}</button>
    </nav>

    <div v-for="(category, index) in content.children" :key="category.index" class="menu-category" :id="category.fields.id">
      <div v-for="(section, index) in category.fields.menuSections" :key="section.index" class="menu-section">
        <div class="animated fadeIn" :class="'section-heading '+ section.fields.headingBackgroundColor">{{section.fields.heading}}</div>
        <div v-if="section.fields.subHeading" class="section-subheading font-bold opacity-0 animated fadeIn delay-100">{{section.fields.subHeading}}</div>

        <div class="flex flex-wrap items-start pt-4 pb-10">
          <img v-if="section.fields.leftImage !== undefined" class="md:mr-auto sm:pr-8 lg:pr-10 mb-8 animated fadeIn" :src="section.fields.leftImage.fields.file.url" :alt="section.fields.leftImage.fields.description" />
          <div class="flex flex-wrap sm:-mx-6 sm:pr-6 flex-1 min-w-59">
            <div v-for="(item, index) in section.fields.menuItems" :key="item.index" class="sm:px-6 pb-10 w-full md:w-1/2 max-450 min-250 animated fadeIn delay-all">
              <menu-item :customClass="item.fields.customClass" :title="item.fields.title" :calories="item.fields.calories" :description="item.fields.description" :items="item.fields.subItems" :extra="{title:item.fields.extraTitle,description:item.fields.extraDescription}"/>
            </div>
          </div>
          <img v-if="section.fields.rightImage !== undefined" class="ml-auto md:pl-6 animated fadeIn" :src="section.fields.rightImage.fields.file.url" :alt="section.fields.rightImage.fields.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem'

export default {
  components: {
    MenuItem
  },
  props: [
    'content'
  ],
  methods: {
    showMenu(e, category) {
      let buttons = document.querySelectorAll('.menu-nav button'), bi
      for (bi = 0; bi < buttons.length; ++bi) {
          buttons[bi].classList.remove('active')
      }
      e.target.classList.add('active')
      let categories = document.querySelectorAll('.menu-category'), i
      for (i = 0; i < categories.length; ++i) {
        categories[i].style.display = 'none'
      }
      document.querySelector('#'+category).style.display = 'block'
    }
  },
  mounted() {
    document.querySelectorAll('.menu-category')[0].style.display = 'block' // Show first section
  }
}
</script>