<template>
  <div class="wrapper mx-auto px-3 md:px-8 xl:px-0">
    <div class="flex flex-wrap justify-center image-text-right">
      <img class="opacity-0" v-animate-onscroll="'animated fadeIn'" data-object-fit="cover" :data-src="content.backgroundImageDesktop.fields.file.url" :src="content.backgroundImageDesktop.fields.file.url" :alt="content.backgroundImageDesktop.fields.description"/>
      <div class="opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'">
        <div v-for="child in content.children" class="image-text-block">
          <h5>{{child.fields.title}}</h5>
          <p>{{child.fields.body.content[0].content[0].value}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>