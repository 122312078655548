<template>
  <div>
    <div class="info-cards wrapper mx-auto px-3 md:px-8 xl:px-0 mt-12 pt-2 pb-6">
      <div v-for="(card, i) in content.children" :key="i" class="info-card opacity-0" v-animate-onscroll="'animated fadeIn'">
        <h4>{{card.fields.title}}</h4>
        <body-copy :content="card.fields.body.content"/>
      </div>
    </div>
    <div class="text-center mb-16 pb-1">
      <nuxt-link :to="content.cta[0].fields.url" class="button purple inline-flex pulse">{{content.cta[0].fields.title}}</nuxt-link>
    </div>
  </div>
</template>

<script>
import BodyCopy from './BodyCopy'

export default {
  components: { BodyCopy },
  props: [
    'content'
  ],
}
</script>