<template>
  <div class="wrapper mx-auto">
    <div class="disclaimer mx-auto">
      <div>
        <h4 class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" v-html="content.heading"/>
        <p class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" v-html="content.body.content[0].content[0].value"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>

<style lang="scss">
  @import '../../assets/scss/_variables';

  .disclaimer {
    > div {
      width: 625px;
      border-top: 1px solid #d8d8d8;
      margin: auto;
      text-align: center;
      padding: 20px 30px;
      margin-bottom: 100px;
      max-width: 100%;
      @media (max-width: $sm) {
        padding: 20px 20px;
      }
      h4 {
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        color: $dark;
        line-height: 1.5;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      p {
        line-height: 2;
        font-size: 18px;
      }
    }
  }
</style>