<template>
  <div class="app-download px-4">
    <div class="relative">
      <img class="opacity-0" v-animate-onscroll="'animated fadeIn'" :src="content.backgroundImageDesktop.fields.file.url" :alt="content.backgroundImageDesktop.fields.description"/>
      <img class="opacity-0 delay-100" v-animate-onscroll="'animated zoomIn fadeIn'" :src="content.backgroundImageMobile.fields.file.url" :alt="content.backgroundImageMobile.fields.description"/>
    </div>
    <div>
      <h2 class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'">{{content.title}}</h2>
      <h3 class="opacity-0 delay-400" v-animate-onscroll="'animated fadeIn'">{{content.heading}}</h3>
      <div class="flex opacity-0 delay-600" v-animate-onscroll="'animated fadeIn'">
        <a class="pulse" v-for="(link, index) in content.cta" :key="index" :href="link.fields.url">
          <img :src="link.fields.image.fields.file.url" :alt="link.fields.image.fields.description"/>
        </a>
      </div>
    </div>
    <i class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'">{{content.body.content[0].content[0].value}}</i>
  </div>
</template>

<script>  
export default {
  props: [
    'content'
  ]
}
</script>