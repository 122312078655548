<template>
  <div class="iframe-wrapper" :class="content.cssClass">
    <iframe :src="content.heading" :title="content.title" width="100%" scrolling="no"></iframe>
  </div>
</template>

<script>
  export default {
    props: [
      'content',
    ]
  }
</script>

<style lang="scss" scoped>
  @import '~/assets/scss/_variables.scss';
  .iframe-wrapper {
    iframe {
      height: 1120px;
      margin: auto;
      @media (min-width: $md) {
        height: 850px;
      }
    }
  }
</style>
