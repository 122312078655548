<template>
  <section :class="content.cssClass" :id="content.slug" class="relative opacity-0 mb-10 default" v-animate-onscroll="'animated fadeIn'">
    <div class="max-w-6xl mx-auto flex flex-wrap">
      <div v-if="defaultImage" class="w-full lg:w-2/3 px-2 lg:px-4 mb-4">
        <default-picture v-if="defaultImage" :sizes="images" :defaultImg="defaultImage" :description="alt"/>
      </div>
      <div class="w-full lg:w-1/3 px-2 lg:px-4">
        <h2 class="bg-purple text-white font-bold text-2xl px-2 py-3 uppercase leading-none mb-4" v-html="content.heading" />
        <div class="mb-4">
          <body-copy :content="content.body.content" class="opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'"/>
        </div>
        <default-button
          class="pulse"
          v-for="cta in content.cta"
          :key="cta.index"
          color="purple"
          :url="cta.fields.url"
          :title="cta.fields.title"
          :internal="cta.fields.internalLink" />
      </div>
    </div>
  </section>
</template>

<script>
import DefaultButton from './DefaultButton'
import DefaultPicture from './DefaultPicture'
import BodyCopy from './BodyCopy'

export default {
  components: {
    DefaultButton,
    DefaultPicture,
    BodyCopy,
  },
  props: [
    'content'
  ],
  computed: {
    images() {
      return this.content.backgroundImageMobile ? [
        {
          url: this.content.backgroundImageMobile.fields.file.url,
          break: 992
        }
      ] : []
    },
    defaultImage() {
      return this.content.backgroundImageDesktop ? this.content.backgroundImageDesktop.fields.file.url : ''
    },
    alt() {
      return this.content.backgroundImageMobile ? this.content.backgroundImageMobile.fields.description : ''
    }
  }
}
</script>
