<template>
  <div class="flex relative z-10 flex-col items-center justify-center" :class="content.cssClass">
      <h1 class="mb-4 text-center text-xl md:text-2xl" v-html="content.heading"/>
      <app-link :content="content.cta[0]" class="pulse text-xs md:text-small"/>
  </div>
</template>

<script>
import AppLink from '~/components/common/AppLink'

export default {
  components: {
    AppLink
  },
  props: [
    'content',
  ],
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.careers-callout {
  background: white;
  padding: 50px;

  @media (min-width: $md) {
    padding: 75px;
  }

  h1 {
    // font-size: 32px;
    font-weight: 600;
  }

  a {
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    background-color: $pink;
    padding: 10px 25px;
    border-radius: 5px;
  }
}
</style>