<template>
  <div class="specials-list wrapper mx-auto px-2 md:px-8 xl:px-0">
    <h2 class="text-5xl text-center font-bold uppercase opacity-0" v-animate-onscroll="'animated fadeIn'" v-if="content.heading" v-html="content.heading"/>
    <h3 v-if="content.body" class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.body.content[0].content[0].value}}</h3>
    <div class="flex flex-wrap experience-item-wrapper -mx-4">
      <div class="w-full sm:w-1/2 opacity-0" v-animate-onscroll="'animated fadeIn'" v-for="special in specials" :key="special.index">
        <special-item :content="special.fields" />
      </div>
    </div>
  </div>
</template>

<script>
import SpecialItem from './SpecialItem'

export default {
  components: {
    SpecialItem
  },
  props: [
    'content'
  ],
  data() {
    return {
      specials: []
    }
  },
  beforeMount() {
    this.content.children.map(s => {
      if(s) {
        this.specials.push(s)
      }
    })
  }
 }
</script>