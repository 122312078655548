<template>
  <div :class="content.cssClass" class="mx-auto flex flex-col items-center justify-center">
    <h2 class="text-center uppercase font-extrabold" v-if="content.heading" v-html="content.heading"/>
    <body-copy class="body-text text-center" :content="content.body.content" />
    <app-link v-if="content.cta" :content="content.cta[0]" class="bg-purple py-2 px-6 pulse text-white font-bold"/>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'
import AppLink from '../common/AppLink'

export default {
  components: {
    BodyCopy,
    AppLink
  },
  props: [
    'content',
    // 'languageToggle'
  ],
  computed: {
    languageToggle() {
      return this.$store.state.languageToggle
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.scavenger-hunt {
  width: 100%;
  max-width: 750px;
  padding: 30px;

  h2 {
    color: #553286;
    font-size: 20px;
  }

  .body-text {
    color: #553286;
    font-size: 20px;
    line-height: 155%;
    font-weight: medium;
  }
}

.lost-pins-rules {
  width: 100%;
  max-width: 750px;
  padding: 30px;
  h2 {
    color: #003393;
    font-size: 16px;
  }

  .body-text {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #003393;
    color: #003393;
    font-size: 12px;
    line-height: 185%;

    @media (min-width: $md) {
      font-weight: bold; 
      line-break: 125%;
    }
  }
}
</style>