<template>
<div class="book-selector px-1">
  <div class="flex flex-wrap text-white uppercase pt-10 justify-center px-4">
    <div class="book-select relative py-4 px-6 mx-1 mb-2 leading-none w-full lg:w-64 cursor-pointer">
      <div class="absolute inset-0 cursor-pointer" v-on:click="toggleTypePicker(!showTypePicker)" />
      <div class="text-sm font-bold mb-1">I want to</div>
      <div  v-html="bookOption.title" />
      <div class="book-options absolute w-full bg-purple -ml-4 z-20" v-if="showTypePicker">
        <div class="mx-4 border-t-2 border-white" />
        <form-options name="book" :selected="bookValue" :options="bookOptions" :handleUpdate="onTypeUpdate" :handleClose="toggleTypePicker" />
      </div>
    </div>
    <div class="book-select relative py-4 px-6 mx-1 sm:mb-2 leading-none w-auto flex-grow lg:flex-grow-0 lg:w-64 cursor-pointer">
      <div class="absolute inset-0 cursor-pointer" v-on:click="toggleDatePicker(!showDatePicker)" />
      <div class="text-sm font-bold mb-1">on</div>
      <div class="" v-html="dateSelected" />
      <div class="book-options absolute w-full bg-purple -ml-4 z-20" v-if="showDatePicker">
        <div class="mx-4 border-t-2 border-white" />
        <book-calendar color="purple" :minimum="minimum" :selected="selected" :handleUpdate="onCalendarUpdate" :handleClose="toggleDatePicker" />
      </div>
    </div>
    <div class="mx-1 sm:mb-2 w-10">
      <a :href="`/book/${bookValue}/`" class="booking-button button purple h-full" v-on:click.prevent="goToBookPage" title="Book Now" />
    </div>
  </div>
</div>
</template>

<script>
import { detect } from 'detect-browser'
import moment from 'moment'
import BookCalendar from '../forms/DatePick'
import FormOptions from '../forms/OptionsPurple'

export default {
  components: {
    BookCalendar,
    FormOptions
  },
  props: [
    'content'
  ],
  data() {
    return {
      minimum: 3,
      showDatePicker: false,
      showTypePicker: false,
      bookValue: 'birthday',
      bookDate: null
    }
  },
  beforeMount() {
    if(!this.bookDate) {
      this.bookDate = moment().add(this.minimum, 'day')
    }
    this.bookValue = this.content.children[0].fields.slug
  },
  computed: {
    mobile() {
      const browser = detect()
      if(browser) {
        switch(browser.os) {
          case 'iOS':
          case 'Android OS':
            return true
            break
          default:
            return false
        }
      } else {
        return false
      }
    },
    bookOptions() {
      let bookingOptions = []
      this.content.children.map(f => {
        bookingOptions.push({
          value: f.fields.slug,
          title: f.fields.title
        })
      })
      return bookingOptions
    },
    bookOption() {
      let option = null
      this.bookOptions.map(b => {
        if(b.value == this.bookValue) {
          option = b
        }
      })
      return option
    },
    dateSelected() {
      return this.selected.format('MMMM D')
    },
    selected() {
      return this.bookDate || moment() //this.$store.state.bookDate || moment()
    }
  },
  methods: {
    toggleTypePicker(show) {
      this.showTypePicker = show
    },
    toggleDatePicker(show) {
      this.showDatePicker = show
    },
    onWindowScroll() {
      this.toggleTypePicker(false)
      this.toggleDatePicker(false)
    },
    bookingFlow() {
      this.$store.commit('cart/resetCart')
      let link = `/book/${this.bookValue}`
      // this.content.children.map(f => {
      //   if(f.fields.slug == this.bookValue && f.fields.steps && f.fields.steps.length > 0) {
      //     link += `/${f.fields.steps[0].fields.slug}`
      //   }
      // })
      this.$router.push(link)
    },
    onTypeUpdate(opt, close = false) {
      this.bookValue = opt.value
      switch(this.bookValue) {
        case 'birthday':
          this.minimum = 3
          break
        case 'virtual-experiences':
          this.minimum = 8
          break
        default:
          this.minimum = 0
      }
      if(close) {
        this.toggleTypePicker(false)
      }
      this.bookDate = moment().add(this.minimum, 'day')
      // this.$store.commit('setBookDate', moment().add(this.minimum, 'day'))
    },
    onCalendarUpdate(date, close = false) {
      this.bookDate = date.date
      // this.$store.commit('setBookDate', date.date)
      if(close) {
        this.toggleDatePicker(false)
      }
    },
    goToBookPage() {
      console.log(this.bookValue, this.selected)
      this.$store.commit('setBookDate', this.selected)
      this.$router.push(`/book/${this.bookValue}/`)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll)
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';
.book-select {
  background: $purple url('../../assets/img/icons/caret-down-white.svg') no-repeat right 1rem center;
  @media (max-width: $lg) {
    &:nth-of-type(1) {
      margin-right: 0;
    }
  }
  @media (max-width: $sm) {
    &:nth-of-type(1) {
      margin-right: 5px;
    }
  }
}

.book-options {
  top: 100%;
}

.booking-button {
  height: 100%;
  background: $purple url('../../assets/img/icons/caret-right.svg') no-repeat center center!important;

  &:hover {
    background: white url('../../assets/img/icons/caret-right-purple.svg') no-repeat center center!important;
  }
}
</style>