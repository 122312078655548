<template>
  <div class="callout flex wrapper mx-auto opacity-0" :class="content.imageRight ? 'image-right ' + content.cssClass : content.cssClass" v-animate-onscroll="'animated fadeIn'">
    <div v-if="content.image" :style="'background-image:url('+content.image.fields.file.url+')'"/>
    <div>
      <h2 v-html="content.heading"/>
      <h3 v-if="content.lowercase" :style="content.lowercase === false ? `text-transform:uppercase` : `text-transform:none;width:100%;` " v-html="content.subheading"/>
      <h3 v-else v-html="content.subheading"/>
      <!-- <pre v-html="content.cta"/> -->
      <app-link v-if="content.cta" class="button inline-flex pulse" :content="content.cta"/>
      <span v-html="content.legal"/>
    </div>
  </div>
</template>

<script>
import AppLink from '~/components/common/AppLink'

export default {
  components: {
    AppLink
  },
  props: [
    'content',
  ],
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';
.callout.image-right {
  > div:first-of-type {
    order: 2;
    background-position: right;
  }
}
#tour-and-travel {
  .callout {
    h3 {
      width: 480px;
      max-width: 100%;
    }
  }
}
</style>