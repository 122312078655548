<template>
  <div class="text-columns flex opacity-0" v-animate-onscroll="'animated fadeIn'">
    <div class="wrapper mx-2 px-4 md:px-8 xl:px-0 flex flex-wrap">
      <div class="col flex" v-if="content.leftColumn">
        <div class="text-section mb-4">
          <div class="img">
            <img v-if="content.leftColumnImage" :src="content.leftColumnImage.fields.file.url" :alt="content.leftColumnImage.fields.file.fileName" />
          </div>
          <body-copy v-if="content.leftColumn" class="column-text opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" :content="content.leftColumn.content" />
        </div>
        <div v-if="content.leftColumnCta" class="w-full opacity-0 mx-4" v-animate-onscroll="'animated fadeIn'">
          <a class="w-full purple button pulse my-2" :href="content.leftColumnCta.fields.url" v-html="content.leftColumnCta.fields.title" />
        </div>
      </div>
      <div class="col flex" v-if="content.rightColumn">
        <div class="text-section">
          <div class="img">
            <img :src="content.rightColumnImage.fields.file.url" :alt="content.rightColumnImage.fields.file.fileName" />
          </div>
          <body-copy v-if="content.rightColumn" class="column-text opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" :content="content.rightColumn.content" />
        </div>
        <div v-if="content.rightColumnCta" class="w-full opacity-0 mx-4" v-animate-onscroll="'animated fadeIn'">
          <a class="w-full purple button pulse my-2" :href="content.rightColumnCta.fields.url" v-html="content.rightColumnCta.fields.title" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BodyCopy from "./BodyCopy";

export default {
  components: {
    BodyCopy,
  },
  props: ["content"],
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.text-columns {
  .wrapper {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    @media (min-width: $md) {
      flex-direction: row;
      height: 850px;
    }

    @media (min-width: $lg) {
      height: auto;
    }
    .col {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @media (min-width: $md) {
        width: 45%;
        height: 100%;
      }

      &:first-of-type {
        @media (max-width: $md) {
          margin-bottom: 50px;
        }
      }
      .text-section {
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;

        &:last-of-type {
          margin-top: 25px;

          @media (min-width: $sm) {
            margin-top: 0;
          }
        }

        @media (min-width: $lg) {
          flex-direction: row;
        }

        .img {
          height: auto;
          width: 50%;
          margin: auto;

          @media (min-width: $md) {
            width: 75%;
          }

          @media (min-width: $lg) {
            width: 42.5%;
          }

          @media (min-width: $xxl) {
            margin: 0;
            margin-top: 45px;
            width: 40%;
          }
        }

        .column-text {
          padding: 0;
          @media (min-width: $lg) {
            width: 52.5%;
          }
        }
      }
    }
  }
}
</style>