<template>
  <section class="safety-know-more wrapper mx-auto px-3 md:px-8 xl:px-0">
    <h3 class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.heading}}</h3>
    <div class="flex flex-wrap justify-center delay-100 opacity-0" v-animate-onscroll="'animated fadeIn'">
      <div v-for="child in content.children" class="text-content">
        <div v-for="content in child.fields.body.content">
          <h5 v-if="content.nodeType == 'heading-5'">{{content.content[0].value}}</h5>
          <p v-if="content.nodeType == 'paragraph'">{{content.content[0].value}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Markdown from '../common/Markdown'
export default {
  props: [
    'content'
  ],
}
</script>