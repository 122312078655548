<template>
  <div class="px-4 special-item">
    <img class="w-full" :src="content.linkImage.fields.file.url" :alt="content.linkImage.fields.description" />
    <body-copy :content="content.linkDescription.content"/>
    <nuxt-link v-if="content.cta.fields.internalLink" :to="content.cta.fields.url" class="button purple pulse">{{content.cta.fields.title}}</nuxt-link>
    <a v-else :href="content.cta.fields.url" target="_blank" class="button purple pulse">{{content.cta.fields.title}}</a>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    BodyCopy,
  },
  props: [
    'content'
  ]
}
</script>
