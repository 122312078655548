<template>
  <div class="experience-slide">
    <div class="relative">
      <div class="px-3 lg:pl-0 lg:pr-64 opacity-0 delay-300" v-animate-onscroll="'animated fadeIn'">
        <default-picture
          v-if="!fields.video"
          :sizes="[
            {
              url: fields.imageMobile.fields.file.url,
              break: 992
            }
          ]"
          class="experience-slide-image"
          :defaultImg="fields.imageDesktop.fields.file.url"
          :description="fields.imageMobile.fields.description" />
          
          <div class="video-wrapper" v-if="fields.video">
            <video playsinline autoplay muted loop>
                <source :src="fields.video.fields.file.url" type="video/mp4"/>
            </video>
          </div>
      </div>
      <div class="flex sm:-mt-16 lg:absolute lg:inset-0 lg:mt-0 lg:px-0 lg:items-center lg:justify-end">
        <div class="experience-slide-text text-white text-center p-8 w-full opacity-0 delay-100" :class="fields.textBackground ? String(fields.textBackground).split(' ').join('-').toLowerCase() : ''" v-animate-onscroll="'animated fadeIn'" >
          <body-copy :content="fields.body.content" class="mt-auto max-w-lg"/>
          <div class="pulse" v-if="fields.cta">
            <default-button
              v-for="cta in fields.cta"
              :key="cta.index"
              :title="cta.fields.title"
              :url="cta.fields.url ? cta.fields.url : ''"
              :internal="cta.fields.internalLink" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from '../common/DefaultButton'
import DefaultPicture from '../common/DefaultPicture'
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    DefaultButton,
    DefaultPicture,
    BodyCopy
  },
  props: [
    'fields'
  ],
  computed: {
    location: function() {
      return this.$store.state.location
    }
  }
}
</script>
