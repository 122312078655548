<template>
  <section :class="content.cssClass" class="relative opacity-0 default" v-animate-onscroll="'animated fadeIn'">
    <default-picture v-if="defaultImage" :sizes="images" :defaultImg="defaultImage" :description="alt"/>
    <div class="video-wrapper" v-if="content.video">
      <video playsinline autoplay muted loop>
          <source :src="content.video.fields.file.url" type="video/mp4"/>
      </video>
    </div>
    <div v-if="content.body" class="flex w-full px-2 z-10 absolute inset-0 mt-0 px-0 lg:items-center lg:justify-end">
      <div :class="{'has-video': content.video}" class="flex flex-wrap justify-between items-center text-white text-center p-8 w-full opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'">
        <body-copy :content="content.body.content" class="opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'"/>
        <div class="opacity-0 delay-700" v-animate-onscroll="'animated fadeIn'">
          <default-button
            class="pulse"
            v-for="cta in content.cta"
            :key="cta.index"
            :url="cta.fields.url"
            :title="cta.fields.title"
            :internal="cta.fields.internalLink" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DefaultButton from './DefaultButton'
import DefaultPicture from './DefaultPicture'
import BodyCopy from './BodyCopy'

export default {
  components: {
    DefaultButton,
    DefaultPicture,
    BodyCopy,
  },
  props: [
    'content'
  ],
  computed: {
    images() {
      return this.content.backgroundImageMobile ? [
        {
          url: this.content.backgroundImageMobile.fields.file.url,
          break: 992
        }
      ] : []
    },
    defaultImage() {
      return this.content.backgroundImageDesktop ? this.content.backgroundImageDesktop.fields.file.url : ''
    },
    alt() {
      return this.content.backgroundImageMobile ? this.content.backgroundImageMobile.fields.description : ''
    }
  }
}
</script>
