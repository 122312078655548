<template>
  <div class="flex flex-wrap event-menu">

    <nav class="menu-nav mr-10 xl:mr-12 px-2 sm:px-0 sm:pb-12">
      <button v-for="category in content.children" :key="category.index" @click="showMenu($event, category.fields.id)" :class="{'active' : category.fields.title == 'Food Packages'}" class="opacity-0 animated fadeIn">{{category.fields.title}}</button>
    </nav>

    <div v-for="category in content.children" :key="category.index" class="menu-category" :id="category.fields.id">
      <h2 class="section-heading opacity-0 animated fadeIn" :class="{
        red: category.fields.title == 'Food Packages',
        blue: category.fields.title == 'Bar Packages',
        purple: category.fields.title == 'Add Ons',
        }">{{category.fields.title}}</h2>
      <div class="section-subheading font-bold opacity-0 animated fadeIn delay-100">{{category.fields.description}}</div>

      <div class="food-packages" v-if="category.fields.id == 'food-packages'">
        <div v-for="section in category.fields.menuSections" :key="category.index" class="food-package opacity-0" :class="section.fields.cssClass" v-animate-onscroll="'animated fadeIn'">
          <h3>{{section.fields.title}}</h3>
          <div class="flex justify-between">
            <h4>{{section.fields.subtitle}}</h4>
            <span class="minimum">{{section.fields.minimum}}</span>
          </div>

          <div class="flex flex-wrap justify-between">
            <div class="w-full sm:w-1/2 sm:pr-8 pb-3">
              <body-copy :content="section.fields.textLeftTop.content" />
            </div>

            <div class="w-full sm:w-1/2 sm:pl-8">
              <img class="pb-12" v-if="section.fields.imageRight" :src="section.fields.imageRight.fields.file.url" :alt="section.fields.imageRight.fields.description" />
            </div>

            <div class="w-full sm:w-1/2 sm:pr-8 package-left-bottom">
              <body-copy :content="section.fields.textLeftBottom.content" />
              <img v-if="section.fields.imageLeft" class="img-left" :src="section.fields.imageLeft.fields.file.url" :alt="section.fields.imageLeft.fields.description" />
            </div>

            <div class="w-full sm:w-1/2 sm:pl-8 package-text-right pr-8 pt-3 sm:pt-0">
              <body-copy :content="section.fields.textRight.content" />
            </div>
          </div>
        </div>

        <p class="max-w-3xl leading-5 mb-12 pb-4 sm:pr-3">{{content.body.content[0].content[0].value}}</p>
      </div>

      <div class="add-ons" v-if="category.fields.id == 'add-ons' || category.fields.id == 'birthday'">
        <div v-for="section in category.fields.menuSections" :key="category.index" class="add-on opacity-0" :class="section.fields.cssClass" v-animate-onscroll="'animated fadeIn'">
          <div class="add-on-banner-image mt-6 sm:mt-12" v-if="section.fields.imageBanner">
            <!-- <div class="label">{{section.fields.imageBanner.fields.title}}</div> -->
            <img :src="section.fields.imageBanner.fields.file.url" :alt="section.fields.imageBanner.fields.description"/>
          </div>
          <h3 class="section-heading mt-8 sm:mt-12" :class="section.fields.headingColor">{{section.fields.title}}</h3>
          <div class="flex flex-wrap">
            <div class="add-on-left-image mt-2 sm:mt-0 sm:ml-auto" v-if="section.fields.imageLeft">
              <!-- <div class="label">{{section.fields.imageLeft.fields.title}}</div> -->
              <img :src="section.fields.imageLeft.fields.file.url" :alt="section.fields.imageLeft.fields.description"/>
            </div>

            <div class="column-one px-3 md:px-0">
              <body-copy :content="section.fields.columnOne.content"  v-if="section.fields.columnOne" />
            </div>
            <div class="column-two px-3 md:px-0 md:pl-16 lg:pl-24" v-if="section.fields.columnTwo">
              <body-copy :content="section.fields.columnTwo.content" />
            </div>

            <div class="add-on-right-image mt-2 sm:mt-0 sm:ml-auto" v-if="section.fields.imageRight">
              <!-- <div class="label">{{section.fields.imageRight.fields.title}}</div> -->
              <img :src="section.fields.imageRight.fields.file.url" :alt="section.fields.imageRight.fields.description"/>
            </div>
          </div>
        </div>
      </div>

      <div class="bar-packages" v-if="category.fields.id == 'bar-packages'">
        <div v-for="section in category.fields.menuSections" :key="category.index" class="bar-package opacity-0" :class="section.fields.cssClass" v-animate-onscroll="'animated fadeIn'">
          <h3>{{section.fields.title}}</h3>
          <h4>{{section.fields.subtitle}}</h4>
          <div class="flex flex-wrap">
            <div class="w-full sm:max-w-xs md:max-w-sm xl:max-w-lg">
              <body-copy :content="section.fields.columnOne.content" />
            </div>
            <div class="w-64">
              <body-copy :content="section.fields.columnTwo.content" />
            </div>
          </div>
          <span v-if="section.fields.legal" class="legal">{{section.fields.legal}}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem'
import BodyCopy from '~/components/common/BodyCopy'

export default {
  components: {
    MenuItem,
    BodyCopy,
  },
  props: [
    'content'
  ],
  methods: {
    showMenu(e, category) {
      let buttons = document.querySelectorAll('.menu-nav button'), bi
      for (bi = 0; bi < buttons.length; ++bi) {
          buttons[bi].classList.remove('active')
      }
      e.target.classList.add('active')
      let categories = document.querySelectorAll('.menu-category'), i
      for (i = 0; i < categories.length; ++i) {
        categories[i].style.display = 'none'
      }
      document.querySelector('#'+category).style.display = 'block'
      window.scrollBy(0, 1)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.event-menu {
  .food-packages {
    max-width: 1070px;

    @media (max-width: $md) {
      padding: 0 15px;
      max-width: 100vw;
    }

    .food-package {
      border-bottom: 2px solid $dark;
      padding: 30px 0 50px 0;

      &:last-of-type {
        border-bottom: 0;
      }

      * {
        max-width: 100%;
      }

      h3 {
        text-transform: uppercase;
        font-size: 36px;
        font-weight: bold;
      }

      h4 {
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 40px;
      }

      .minimum {
        font-family: "urw-din-condensed";
        font-size: 18px;
        padding-right: 30px;
        white-space: nowrap;
        padding-left: 20px;

        @media (max-width: $md) {
          padding-right: 0;
        }
      }

      strong {
        font-size: 18px;
      }

      .img-left {
        margin-top: -40px;

        @media (max-width: 1320px) {
          margin-bottom: 20px;
          margin-top: 0;
        }

        @media (max-width: $sm) {
          margin-top: -40px;
          margin-bottom: 15px;
        }
      }

      .package-text-right {
        div {
          margin-bottom: 0;
        }

        p {
          width: 400px;
          max-width: 100%!important;
        }

        p:nth-of-type(3) {
          strong {
            margin-top: 62px;
            display: block;
            @media (max-width: $sm) {
              margin-top: 10px;
            }
          }
        }

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .pizza {
      div div div p:first-of-type strong {
        margin-top: 0;
      }

      div div div p:nth-of-type(3) strong {
        font-weight: 600;
        font-size: 16px;
        margin-top: 0;
      }

      img {
        max-height: 470px;
        margin-left: auto;
        padding-right: 30px;

        @media (max-width: $sm) {
          padding-right: 0px;
          max-height: unset;
          margin-left: unset;
        }
      }


      strong {
        margin-top: 30px;
        display: block;
      }

      .package-text-right, .package-left-bottom {
        display: none;
      }
    }
  }

  .section-subheading {
    font-size: 18px;
    color: $dark;

    @media (max-width: $md) {
      padding: 0 12px;
    }
  }
}

.add-on-banner-image, .add-on-right-image, .add-on-left-image {
  position: relative;
  display: block;

  img {
    width: 100%;
  }

  .label {
    background: $red;
    color: #fff;
    font-family:"urw-din-condensed";
    text-transform: uppercase;
    font-weight: 800;
    height: 53px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 17px;
    position: absolute;
    bottom: 40px;
    left: 0;
    line-height: 1.1;
    z-index: 1;
    max-width: 60%;

    @media (max-width: $sm) {
      bottom: 20px;
      height: 40px;
      font-size: 16px;
      padding: 0 10px 0 15px;
      line-height: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0px;
      padding-left: 20px;
      width: 37px;
      height: 100%;
      max-width: 60%;
      background: $red;
      transform-origin: 0 100%;
      transform: skew(-32deg);
      z-index: -1;
    }
  }
}

.add-on-left-image {
  margin-left: 0;
}

.add-ons, .event-menu .birthday {
  max-width: 1020px;

  .section-heading {
    margin-bottom: 45px;

    @media (max-width: $sm) {
      margin-bottom: 30px;
    }
  }

  strong {
    font-size: 18px;
    text-transform: uppercase;
  }
  * {
    max-width: 100vw;
  }

  .add-on:last-of-type {
    margin-bottom: 60px
  }

  .column-one, .column-two {
    max-width: 50%;

    @media (max-width: $sm) {
      max-width: 100%;
    }
  }

  .stations, .party-buckets {
    .column-one {
      max-width: 100%;
      width: 284px;
      margin-right: 50px;

      div {
        margin-bottom: 0;

        p {
          margin-bottom: 34px;
        }

        p:nth-of-type(1) {
          width: 160px;
        }
        p:nth-of-type(3) {
          width: 160px;
        }
      }
    }

    img {
      min-height: 400px;
      object-fit: cover;

      @media (max-width: $sm) {
        min-height: auto;
      }
    }

    .add-on-right-image {
      margin-top: -100px;
      margin-right: -110px;

      @media (max-width: 1095px) {
        margin-right: 0
      }
    }

    .flex {
      flex-wrap: nowrap;

      @media (max-width: 940px) {
        flex-wrap: wrap;
      }
    }

    .label {
      background: $blue;

      &:after {
        background: $blue;
      }
    }

     p {
      width: 290px;
      max-width: 100%;
      display: inline-flex;
      text-transform: uppercase;
      margin-right: 40px;
    }
  }

  .feed-the-team, .platters-and-pizza {

    .flex-wrap {
      flex-wrap: nowrap;

      @media (max-width: 1095px) {
        flex-wrap: wrap;
      }
    }

    .label {
      background: $pink;
      &:after {
        background: $pink;
      }
    }

    .add-on-left-image {
      height: 504px;
      object-fit: cover;

      @media (max-width: 1300px) {
        height: 400px;
      }

      @media (max-width: 1095px) {
        height: auto;
        margin-bottom: 35px;
      }
    }

    .add-on-right-image {
      width: 323px;

      @media (max-width: 985px) {
        margin-top:10px;
      }
    }

    .column-two {
      padding-left: 50px;
      padding-right: 20px;

      @media (max-width: 1095px) {
        padding-left: 0;
      }

      @media (max-width: $sm) {
        padding-left: 0.75rem;
      }
    }

    @media (max-width: 1300px) {
      // .column-two {
      //   padding-left: 40px;
      //   padding-right: 20px;
      // }
    }

    @media (max-width: $sm) {

      // .column-two {
      //   padding: 0 14px;
      // }

      // .add-on-right-image {
      //   width: 100%;
      // }

      .column-one {
        display: none;
      }

      .column-two div {
        margin-bottom: 0;
      }
    }
  }
}

.great-for-meetings {
  .flex-wrap {
    flex-wrap: nowrap;

    @media (max-width: 1300px) {
      flex-wrap: wrap;
    }
  }

  p {
    width: 300px;
    max-width: 100%;
  }

  img {
    height: 480px;
    object-fit: cover;

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  .add-on-right-image {
    height: 480px;
    margin-top: -100px;
    margin-right: -110px;

    @media (max-width: 1300px) {
      margin-top: 0px;
      margin-right: 0px;
      height: auto;
    }

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  .column-one {
    width: 290px;
  }

  .column-two {
    padding-left: 60px;

    @media (max-width: 1360px) {
      padding-left: 40px;
    }

    @media (max-width: $md) {
      padding-left: 14px;
    }
  }

  div p:nth-of-type(2) {
    margin-bottom: 20px;
    min-height: 200px;

    @media (max-width: $sm) {
      // margin: 0;
      min-height: 0;
    }
  }

  div:nth-of-type(2) {
    p {
      strong {
        white-space: nowrap;

        @media (max-width: 946px) {
          white-space: normal;
        }
      }
    }

    @media (max-width: 946px) {
      p:nth-of-type(2) {
        min-height: 167px;
      }
    }

    @media (max-width: $sm) {
      p:nth-of-type(2) {
        min-height: 0;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: $sm) {
    div p {
      margin-bottom: 10px;
    }
  }
}

.beverage-options, .event-menu .sweets {
  .column-one {
    padding-right: 50px;
  }
  .column-two {
    display: none;
  }
  .add-on-right-image {
    margin-right: -110px;
    max-width: 850px;
  }
}

.drink-ticket-options {
  div p:nth-of-type(2) {
    font-size: 12px;
    margin-top: -10px;
  }
}
</style>