<template>
<div>
  <slider class="experience-slider" v-bind="settings" @afterChange="handleChange">
    <experience-slide v-for="s in slides" :key="s.index" :fields="s.fields" />
    <template #prevArrow>
      <button class="backward pink pulse">Prev</button>
    </template>
    <template #nextArrow>
      <button class="forward pink pulse">Next</button>
    </template>
  </slider>
</div>
</template>

<script>
import Slider from 'vue-slick-carousel'
import ExperienceSlide from './ExperienceSlide'

export default {
  components: {
    Slider,
    ExperienceSlide
  },
  props: [
    'slides'
  ],
  data: function() {
    return {
      settings: {
        arrows: true,
        dots: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 7000,
      }
    }
  },
  methods: {
    handleChange() {
      for(var i = 0; i < document.querySelectorAll('.slick-slide a').length; i++) {
        let x = document.querySelectorAll('.slick-slide a')[i].getBoundingClientRect().x
        if(x < window.innerWidth && x > 0) {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = 0
        } else {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = -1
        }
      }
    }
  }
}
</script>

<style lang="scss">
.experience-slider {
  .slick-arrow {
    position: absolute;
    bottom: .4rem;
    right: 0;
    width: 6rem;
    z-index: 20;

    &.slick-prev {
      right: 7rem;
    }
  }
  .slick-dots {
    width: auto;
    left: 0;
    top: 0;
    bottom: auto;
    padding: .75rem 0;

    li {
      margin: 0 .5rem .5rem;
    }

    button {
      width: .75rem;
      height: .75rem;
      border: 1px solid white;
      background-color: transparent;
    }
  }
}
</style>