<template>
  <div class="birthday-packages">
    <div class="wrapper mx-auto px-3 md:px-8 xl:px-0">
        <nav class="overflow-x-scroll flex">
            <a v-if="vePackage.fields.title" :href="'#'+vePackage.fields.title.split(' ').join('-').split('&').join('n').toLowerCase()" class="whitespace-no-wrap mr-2 opacity-0" v-animate-onscroll="'animated fadeIn'" v-for="(vePackage, index) in content.children" :key="vePackage.index">
                {{vePackage.fields.title}}
            </a>
        </nav>
    </div>
    <div class="wrapper mx-auto px-3 md:px-8 xl:px-0">
        <div v-for="(vePackage, index) in content.children" :key="vePackage.index" class="opacity-0" v-animate-onscroll="'animated fadeIn'">
            <VEPackageDetails
              :id="vePackage.fields.title.split(' ').join('-').split('&').join('n').toLowerCase()"
              :data-scroll-item="index"
              :key="index"
              :title="vePackage.fields.title"
              :price="vePackage.fields.price"
              :description="vePackage.fields.description"
              :includes="vePackage.fields.includes"
              :imageDesktop="vePackage.fields.imageDesktop"
              :imageMobile="vePackage.fields.imageMobile"
            />
        </div>
        <div class="text-center">
          <nuxt-link to="/" class="button purple mx-auto mb-12 opacity-0" v-animate-onscroll="'animated fadeIn'" style="display:inline-flex">Back Home</nuxt-link>
        </div>
    </div>
  </div>
</template>

<script>
// import util from '~/assets/js/utils.js'
import VEPackageDetails from './VEPackageDetails.vue'
import $ from 'jquery'

export default {
  components: {
    VEPackageDetails
  },
  props: [
    'content'
  ],
  mounted() {
    $(function() {
      if (window.location.hash) {
        try {
          $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top - 65
          }, 800)
        } catch(err) {
          // ignore element not found
        }
      }
    })
    window.onhashchange = function(e) {
      window.scrollTo(0, 0)
      try {
        $('html, body').animate({
          scrollTop: $(window.location.hash).offset().top - 65
        }, 800)
      } catch(err) {
        // ignore element not found
      }
    }
  }
}
</script>