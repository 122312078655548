<template>
  <div class="photo-cards pb-12">
    <h1 v-if="content.title" v-html="content.title" class="opacity-0" v-animate-onscroll="'animated fadeIn'"/>
    <h2 v-if="content.body" v-html="content.body.content[0].content[0].value" class="opacity-0" v-animate-onscroll="'animated fadeIn'"/>
    <div class="wrapper mx-auto px-2 md:px-8 xl:px-0 mx-auto flex flex-wrap opacity-0 pb-10" v-animate-onscroll="'animated fadeIn'" :class="content.cssClass">
      <div class="flex flex-wrap -mx-1 sm:-mx-2 mb-4 justify-center animate-cards w-full">
        <div v-for="(a,index) in content.children" :key="a.index" class="w-1/2 md:w-1/3 lg:w-1/4">
          <photo-card
            v-if="a.fields"
            :btnText="a.fields.cta.fields.title"
            :href="a.fields.cta.fields.url"
            :title="a.fields.title"
            :date="a.fields.date"
            :blurb="a.fields.excerpt"
            :linkImage="a.fields.linkImage ? a.fields.linkImage.fields.file.url : ''"
            :linkImageAlt="a.fields.linkImage ? a.fields.linkImage.fields.description : ''" />
        </div>
      </div>
      <nuxt-link v-if="content.cta" :to="content.cta[0].fields.url" class="mx-auto opacity-0" v-animate-onscroll="'animated fadeIn'"><button class="pulse purple block">{{content.cta[0].fields.title}}</button></nuxt-link>
    </div>
  </div>
</template>

<script>
import PhotoCard from './PhotoCard'

export default {
  data() {
    return {
      minHeight: ''
    }
  },
  components: {
    PhotoCard
  },
  props: [
    'content'
  ],
  mounted() {
    window.addEventListener("resize", this.resizeArticleCards)
    this.$nextTick(() => {
      this.resizeArticleCards()
    })
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeArticleCards)
  },
  methods: {
    resizeArticleCards() {
      let cards = document.querySelectorAll('.article-card-body')
      let i
      for (i = 0; i < cards.length; i++) {
        cards[i].removeAttribute('style')
        if (i == 0) {
          this.minHeight = cards[i].offsetHeight
        } else {
          if (cards[i].offsetHeight > cards[i-1].offsetHeight) {
            if (cards[i].offsetHeight > this.minHeight) {
              this.minHeight = cards[i].offsetHeight
            }
          }
        }
      }
      for (i = 0; i < cards.length; i++) {
        cards[i].style.minHeight = this.minHeight + 'px'
      }
      if (this.minHeight < 180) {
        this.minHeight = 180
      }
    },
  }
}
</script>