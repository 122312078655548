// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/icons/caret-down-white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/icons/caret-right.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/icons/caret-right-purple.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".book-select[data-v-d03cb67c]{background:#8a0ffe url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right 1rem center}@media(max-width:1024px){.book-select[data-v-d03cb67c]:first-of-type{margin-right:0}}@media(max-width:640px){.book-select[data-v-d03cb67c]:first-of-type{margin-right:5px}}.book-options[data-v-d03cb67c]{top:100%}.booking-button[data-v-d03cb67c]{height:100%;background:#8a0ffe url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%!important}.booking-button[data-v-d03cb67c]:hover{background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 50%!important}", ""]);
// Exports
module.exports = exports;
