<template>
  <div class="special-banner animated fadeIn">
    <div class="wrapper mx-auto px-2 md:px-8 xl:px-0 flex items-center justify-center">
      <span>{{content.text}}</span>
      <app-link :content="content.link" class="pulse"/>
    </div>
  </div>
</template>

<script>
import AppLink from '~/components/common/AppLink'

export default {
  components: {
    AppLink
  },
  props: [
    'content',
  ],
}
</script>