<template>
  <div class="birthday-packages">
    <div class="wrapper mx-auto px-3 md:px-8 xl:px-0">
        <nav class="overflow-x-scroll flex">
            <a v-if="bdayPackage.fields.title" :href="'#'+bdayPackage.fields.title.split(' ').join('-').split('&').join('n').toLowerCase()" class="whitespace-no-wrap mr-2 opacity-0" v-animate-onscroll="'animated fadeIn'" v-for="(bdayPackage, index) in content.children" :key="bdayPackage.index">
                {{bdayPackage.fields.title}}
            </a>
            <a v-if="this.$route.name == 'birthdays-packages'" href="#birthday-party-add-ons" class="whitespace-no-wrap mr-2 opacity-0" v-animate-onscroll="'animated fadeIn'">
              Birthday Party Add Ons
            </a>
        </nav>
    </div>
    <div class="wrapper mx-auto px-3 md:px-8 xl:px-0">
        <div v-for="(bdayPackage, index) in content.children" :key="bdayPackage.index" class="opacity-0" v-animate-onscroll="'animated fadeIn'">
            <package-details
              :id="bdayPackage.fields.title.split(' ').join('-').split('&').join('n').toLowerCase()"
              :data-scroll-item="index"
              :key="index"
              :title="bdayPackage.fields.title"
              :price="bdayPackage.fields.price"
              :description="bdayPackage.fields.description"
              :includes="bdayPackage.fields.includes"
              :imageDesktop="bdayPackage.fields.imageDesktop"
              :imageMobile="bdayPackage.fields.imageMobile"
            />
        </div>
        <div class="text-center">
          <nuxt-link to="/" class="button purple mx-auto mb-12 opacity-0" v-animate-onscroll="'animated fadeIn'" style="display:inline-flex">Back Home</nuxt-link>
        </div>
    </div>
  </div>
</template>

<script>
// import util from '~/assets/js/utils.js'
import PackageDetails from './PackageDetails.vue'
import $ from 'jquery'

export default {
  components: {
    PackageDetails
  },
  props: [
    'content'
  ],
  mounted() {
    console.log(this.$route.name)
    $(function() {
      if (window.location.hash) {
        try {
          $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top - 65
          }, 800)
        } catch (err) {
          // ignore element not found
        }
      }
    })
    window.onhashchange = function(e) {
      window.scrollTo(0, 0)
      try {
        $('html, body').animate({
          scrollTop: $(window.location.hash).offset().top - 65
        }, 800)
      } catch(err) {
        // ignore element not found
      }
    }
  }
}
</script>