<template>
  <div class="flex relative z-10 flex-col items-center justify-center pt-8" :class="content.cssClass">
      <img class="m-0" :src="lockup" :alt="content.heading">
      <div v-for="c in content.children" :key="c.index" class="culture-container w-full md:w-2/3 flex items-center justify-between pb-0 md:pb-8 my-4 p-8">
        <div class="content flex items-center justify-center">
          <img class="mb-4" :src="c.fields.image.fields.file.url" :alt="c.fields.image.fields.title">
        </div>
        <div class="content flex flex-col items center md:items-start justify-center">
          <h2 class="text-center md:text-left text-xl font-bold uppercase mb-2" v-html="c.fields.title"/>
          <body-copy class="text-center md:text-left" :content="c.fields.body.content" />
        </div>
      </div>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    BodyCopy
  },
  props: [
    'content',
  ],
  computed: {
    lockup() {
      return this.content.body.content[0].data.target.fields.file.url
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.culture-section {
  background: white;
  padding-top: 50px;
  background-position: top;

  @media (min-width: $md) {
    padding-top: 75px;
  }

  .culture-container {
    flex-direction: column;
    @media (min-width: $md) {
      flex-direction: row;

      &:nth-of-type(2n) {
        flex-direction: row-reverse;
      }
    }
    .content {
      width: 100%;
      @media (min-width: $md) {
        width: 55%;
        &:nth-of-type(1) {
          width: 35%;
        }
      }
    }
  }
}
</style>