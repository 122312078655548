<template>
  <form id="virtual-contact-form" :class="content && content.cssClass" class="relative contact opacity-0" v-on:submit.prevent="submitContactForm" v-animate-onscroll="'animated fadeIn'">
    <h3 class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'">{{content ? content.heading : 'Want Help Planning Your Party?'}}</h3>
    <div class="bg-white px-6 sm:px-10 py-6 mt-2 pb-8">
      <div class="flex flex-wrap">
        <div v-for="f in fields" :key="f.index" :class="`relative mb-6 sm:mb-8 ${getWidth(f)} ${errors.indexOf(f.name) > -1 ? 'error' : ''}`" :data-error="f.errorMsg">
          <label :for="f.name" class="main-label" v-html="f.label" />
          <div v-if="f.type == 'textarea'">
            <textarea :id="f.name" :name="f.name" :placeholder="f.label" @keyup="getCharsRemaining" v-model="model[f.name]" maxlength="256"></textarea>
            <span class="float-left">* No special characters allowed except /</span>
            <span class="float-right">{{charsRemaining}} Characters Remaining</span>
          </div>
          <date-field v-else-if="f.type == 'date'" :field="f" :model="model[f.name]" :handleUpdate="onCalendarChange" />
          <div v-else-if="f.type == 'select'">
            <mobile-select v-if="mobile" :field="f" :model="model" :handleUpdate="updateSelect" />
            <default-select v-else :field="f" :model="model" :handleUpdate="updateSelect" />
          </div>
          <div v-else-if="f.type == 'checkbox'" class="flex">
            <input type="checkbox" :id="f.name" :name="f.name" v-model="model[f.name]" />
            <label :for="f.name" v-html="f.label" />
          </div>
          <input v-else :type="f.type" :id="f.name" :name="f.name" :placeholder="f.label" v-model="model[f.name]" />
        </div>
      </div>

      <div>
        <button class="pulse purple">Submit</button>
      </div>
    </div>
    <div v-if="sending" class="absolute inset-0 m-1 mt-10 flex items-center justify-center bg-white-95 uppercase">
      <div>Sending</div>
    </div>
    <div v-else-if="complete" class="absolute inset-0 m-1 mt-10 flex items-center justify-center bg-white-95 uppercase text-center">
      <div>THANK YOU!<br />A member of our team will be in touch with you shortly.</div>
    </div>
  </form>
</template>

<script>
import { detect } from 'detect-browser'
import moment from 'moment'
import DefaultSelect from '../forms/Select'
import MobileSelect from '../forms/MobileSelect'
import DateField from '../forms/DateField'
import DatePick from '../forms/DatePick'
const states = require('~/assets/config/states.json')
const contactFields = require('~/assets/config/virtualForm.json')

export default {
  components: {
    DefaultSelect,
    MobileSelect,
    DateField,
    DatePick
  },
  props: {
    content: {
      type: Object,
    }
  },
  data: function() {
    let stateOpts = {}
    Object.keys(this.$store.state.centerData).map(c => {
      let center = this.$store.state.centerData[c]
      if(!stateOpts[center.state]) {
        stateOpts[center.state] = {
          title: states[center.state],
          values: []
        }
      }
      stateOpts[center.state].values.push({
        value: `${center.name}, ${center.state}`,
        title: `${center.name}, ${center.state}`
      })
    })

    let locations = []
    Object.keys(stateOpts).map(s => {
      stateOpts[s].values.sort(this.sortOptions)
      locations.push(stateOpts[s])
    })
    locations.sort(this.sortOptions)
    locations.unshift({
      values: [
        {
          value: 'No Preference',
          title: 'No Preference'
        }
      ]
    })

    let fields = []
    let model = {}
    contactFields.map(f => {
      if(f.name == 'location') {
        f.options = locations
      }
      fields.push(f)
      model[f.name] = null
    })

    return {
      charsRemaining: 256,
      calendar: null,
      fields: fields,
      model: model,
      errors: [],
      sending: false,
      complete: false
    }
  },
  computed: {
    mobile() {
      const browser = detect()
      if(browser) {
        switch(browser.os) {
          case 'iOS':
          case 'Android OS':
            return true
            break
          default:
            return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    async submitContactForm() {
      let errors = []
      this.fields.map(f => {
        if(!this.validate(f)) {
          errors.push(f.name)
        }
      })
      this.errors = errors

      console.log(this.model)

      if(errors.length < 1) {
        this.sending = true
        let status = await this.$axios.post(`${this.$api.url()}virtualexperiences`, this.model)
        this.sending = false
        this.complete = true
      }
    },
    validate(f) {
      switch(f.type) {
        case 'checkbox':
          if(f.required && this.model[f.name] != true) {
            return false
          }
          break
        case 'date':
          if(f.required && this.model[f.name] == null) {
            return false
          }
          break
        case 'tel':
          if(f.required || (this.model[f.name] != null && this.model[f.name] != '')) {
            return this.model[f.name] && this.model[f.name].match(/^[\(]?[1-9]{1}[0-9]{2}[\)\s\-\.]*[1-9]{1}[0-9]{2}[\s\-\.]?[0-9]{4}/)
          }
          break
        default:
          if(f.required || (this.model[f.name] != null && this.model[f.name] != '')) {
            return this.model[f.name] && this.model[f.name].match(/^[A-Za-z0-9]+/)
          }
      }
      return true
    },
    openCalendar(cal) {
      this.calendar = cal
    },
    formatDate(f) {
      if(this.model[f.name] == null) {
        return f.label
      } else {
        return moment(this.model[f.name]).format(f.format)
      }
    },
    onCalendarChange(d) {
      this.model[d.name] = d.date.format('MM/DD/YYYY')
    },
    updateSelect(name, value) {
      this.model[name] = value
    },
    getCharsRemaining() {
      let textarea = document.querySelector('[name=message]')
      this.charsRemaining = 256-textarea.value.length
    },
    getWidth(f) {
      if(f.width) {
        if(f.width == 'full') {
          return 'w-full'
        } else if(f.width == 'auto') {
          return ''
        }
      }
      return 'w-full sm:w-1/2 sm:pr-2 sm:pr-8'
    },
    sortOptions(a, b) {
      if(a.title < b.title) {
        return -1
      } else if(a.title > b.title) {
        return 1
      } else {
        return 0
      }
    }
  },
}
</script>

<style lang="scss">
</style>