<template>
  <div class="text-columns opacity-0" v-animate-onscroll="'animated fadeIn'">
    <div class="wrapper mx-auto px-2 md:px-8 xl:px-0 flex flex-wrap">
      <div class="text-section">
        <body-copy class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" :content="content.leftColumn.content" />
      </div>
      <div class="text-section">
        <body-copy class="opacity-0 delay-400" v-animate-onscroll="'animated fadeIn'" :content="content.rightColumn.content" />
      </div>
    </div>
  </div>
</template>

<script>
import BodyCopy from './BodyCopy'

export default {
  components: {
    BodyCopy
  },
  props: [
    'content'
  ]
}
</script>