<template>
  <div class="bday-package-card">
    <div v-if="content.fields.title == 'The Main Event'" class="best-value">Best Value!</div>
    <h3>{{content.fields.title}}</h3>
    <p>{{content.fields.tagLine}}</p>
    <div class="bday-package-card-price">
      <sup>$</sup>
      <span>{{priceBase}}</span>
      <sup>{{priceSup}}</sup>
      <span>Per Guest</span>
    </div>
    <nuxt-link :to="content.fields.slug" class="pulse">Learn More</nuxt-link>
  </div>
</template>

<script>
export default {
  props: [
    'content',
  ],
  computed: {
    priceBase() {
      return this.content.fields.price.toString().split('.')[0]
    },
    priceSup() {
      if (this.content.fields.price.toString().split('.')[1] == null) {
        return '00'
      }
      return this.content.fields.price.toString().split('.')[1]
    }
  }
}
</script>