<template>
  <section class="food-and-fun wrapper mx-auto px-3 md:px-8 xl:px-0">
    <img class="opacity-0" v-animate-onscroll="'animated fadeIn'" :src="content.body.content[0].data.target.fields.file.url" :alt="content.body.content[0].data.target.fields.description"/>
    <p class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.heading}}</p>
    <div class="flex flex-wrap justify-center text-center">
      <div class="box opacity-0" v-animate-onscroll="'animated fadeIn'">
        <h4 class="font-brush">{{content.children[0].fields.title}}</h4>
        <p>{{content.children[0].fields.body.content[0].content[0].value}}</p>
        <div class="flex justify-between w-48 mx-auto mt-6">
          <div>
            <span class="font-brush leading-none">{{content.children[0].fields.body.content[1].content[0].value}}</span>
            <span class="text-2xl leading-none">{{content.children[0].fields.body.content[2].content[0].value}}</span>
          </div>
          <div>
            <span class="font-brush leading-none">{{content.children[0].fields.body.content[3].content[0].value}}</span>
            <span class="text-2xl leading-none">{{content.children[0].fields.body.content[4].content[0].value}}</span>
          </div>
        </div>
      </div>

      <div class="box opacity-0" v-animate-onscroll="'animated fadeIn'">
        <h4 class="font-brush">{{content.children[1].fields.title}}</h4>
        <p>{{content.children[1].fields.body.content[0].content[0].value}}</p>
        <div class="flex justify-between w-48 mx-auto mt-6">
          <div>
            <span class="font-brush leading-none">{{content.children[1].fields.body.content[1].content[0].value}}</span>
            <span class="text-2xl leading-none">{{content.children[1].fields.body.content[2].content[0].value}}</span>
          </div>
          <div>
            <span class="font-brush leading-none">{{content.children[1].fields.body.content[3].content[0].value}}</span>
            <span class="text-2xl leading-none">{{content.children[1].fields.body.content[4].content[0].value}}</span>
          </div>
        </div>
      </div>

      <div class="box opacity-0" v-animate-onscroll="'animated fadeIn'">
        <h4 class="font-brush">{{content.children[2].fields.title}}</h4>
        <p>{{content.children[2].fields.body.content[0].content[0].value}}</p>
        <div class="flex flex-wrap">
          <div class="flex flex-col flex-wrap sm:w-1/2 sm:pr-2">
            <span class="font-brush">{{content.children[2].fields.body.content[1].content[0].value}}</span>
            <b class="whitespace-pre">{{content.children[2].fields.body.content[2].content[0].value}}</b>
            <img class="mt-auto" :src="content.children[2].fields.body.content[3].data.target.fields.file.url" :alt="content.children[2].fields.body.content[3].data.target.fields.description">
          </div>
          <div class="flex flex-col flex-wrap sm:w-1/2 sm:pl-2">
            <span class="font-brush">{{content.children[2].fields.body.content[4].content[0].value}}</span>
            <b class="whitespace-pre">{{content.children[2].fields.body.content[5].content[0].value}}</b>
            <img class="mt-auto" :src="content.children[2].fields.body.content[6].data.target.fields.file.url" :alt="content.children[2].fields.body.content[6].data.target.fields.description">
          </div>
        </div>
      </div>
    </div>

    <p class="opacity-0 whitespace-pre-wrap" v-animate-onscroll="'animated fadeIn'">{{content.children[3].fields.value}}</p>
  </section>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>