<template>
  <div class="bday-package-slider opacity-0" v-animate-onscroll="'animated fadeIn'">
    <h2>{{content.title}}</h2>
    <slider :arrows="true" v-bind="settings" @afterChange="handleChange">

      <template #prevArrow>
        <img src="~/assets/img/icons/caret-left-purple.svg" alt="Prev">
      </template>

      <template #nextArrow>
        <img src="~/assets/img/icons/caret-right-purple.svg" alt="Next">
      </template>

      <div v-for="(bdayPackage, index) in content.children" :key="index" class="bday-package-card opacity-0" v-animate-onscroll="'animated fadeIn'">
        <div :class="`best-value ${bdayPackage.fields.title == 'The Main Event' ? 'inline' : 'hidden'}`">Best Value!</div>
        <div :class="`best-value ${bdayPackage.fields.title == 'All You Can Play Birthday' ? 'inline' : 'hidden'}`">New!</div>
        <h3>{{bdayPackage.fields.title}}</h3>
        <p>{{bdayPackage.fields.tagLine}}</p>
        <div class="bday-package-card-price">
          <sup>$</sup>
          <span>{{priceBase(bdayPackage)}}</span>
          <sup>{{priceSup(bdayPackage)}}</sup>
          <span>Per Guest</span>
        </div>
        <nuxt-link :to="bdayPackage.fields.slug" class="pulse">Learn More</nuxt-link>
      </div>
    </slider>

    <div class="text-center mt-5 sm:mt-8">
      <nuxt-link class="sm:mt-3 cta button purple pulse" :to="content.cta[0].fields.url">
        {{content.cta[0].fields.title}}
      </nuxt-link>
    </div>

    <div class="legal">
      {{content.body.content[0].content[0].value}}
    </div>
  </div>
</template>

<script>
  import Slider from 'vue-slick-carousel'
  import PackageCard from '~/components/birthdays/PackageCard'

  export default {
    components: {
      Slider,
      PackageCard
    },
    props: [
      'content',
    ],
    data: function() {
      return {
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1250,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 1090,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                centerMode: true,
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
        }
      }
    },
    methods: {
      priceBase(content) {
        return content.fields.price.toString().split('.')[0]
      },
      priceSup(content) {
        if (content.fields.price.toString().split('.')[1] == null) {
          return '00'
        }
        return content.fields.price.toString().split('.')[1]
      },
      handleChange() {
        for(var i = 0; i < document.querySelectorAll('.slick-slide a').length; i++) {
          let x = document.querySelectorAll('.slick-slide a')[i].getBoundingClientRect().x
          if(x < window.innerWidth && x > 0) {
            document.querySelectorAll('.slick-slide a')[i].tabIndex = 0
          } else {
            document.querySelectorAll('.slick-slide a')[i].tabIndex = -1
          }
        }
      }
    }
  }
</script>

<style lang="scss">
.bday-package-slider {
  .slick-next, .slick-prev {
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -41px;
    cursor: pointer;
    z-index: 5;
    padding: 20px;

    @media (max-width: 375px) {
      right: 0px;
    }
  }

  .slick-prev {
    left: 30px;

    @media (max-width: 375px) {
      left: 0px;
    }
  }
      a[href="/birthdays/packages/"]  {
      background: black;
      border: 3px solid black;
    }
     a[href="/birthdays/packages/"]:hover  {
      background: white;
      color: black;
    }
}
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.bday-package-slider .slick-list
{
    position: relative;
    width: 1250px;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;

    @media (max-width: 1250px) {
      width: 1050px;
    }

    @media (max-width: 1090px) {
      width: 880px;
    }

    @media (max-width: 960px) {
      width: 87%;
    }

    @media (max-width: 840px) {
      width: 100%;
    }
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
</style>