<template>
  <div class="details-section items-center justify-center">
    <div class="flex flex-col" :class="content.cssClass">
      <h3 v-if="content.heading" class="mb-2 opacity-0" v-animate-onscroll="'animated fadeIn'" v-html="content.heading"/>
      <div class="flex flex-wrap items-start justify-start">
        <div v-for="child in content.children" :key="child.index" class="card flex flex-wrap items-center justify-center opacity-0" v-animate-onscroll="'animated fadeInUp'" >
          <div class="top" :style=" `background-image: url(` + child.fields.backgroundImage.fields.file.url + `);`"/>
          <div class="bottom flex items-start justify-center">
            <div class="text flex items-center justify-center">
              <h4 class="text-center text-white font-bold uppercase">
                {{ child.fields.title }}
              </h4>
            </div>
          </div>
          <nuxt-link v-if="child.fields.url" :to="child.fields.url" :title="content.title" class="button pulse" v-html="languageToggle === true ? 'Aprende Más' : 'Learn More'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content", /*"languageToggle"*/],
  computed: {
    languageToggle() {
      return this.$store.state.languageToggle
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.nco-events, .nco-experiences {
  max-width: 350px;

  @media (min-width: $sm) {
    max-width: 675px;
  }

  @media (min-width: $lg) {
    max-width: 1150px;
  }

  &.nco-experiences {
    .card {
      margin: auto;
      margin-bottom: 25px;

      @media (min-width: $sm) {
        margin-left: 0;
      }

      @media (min-width: $lg) {
        width: 31%;
        margin-right: 3%;

        &:nth-of-type(2n) {
          margin-right: 3%;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }

      .top {
        height: 185px;
        background-position: top;
      }
      .bottom {
        height: 75px;
      }
      .button {
        margin-top: 0;
      }
    }
  }

  h3 {
    color: $purple;
  }

  .card {
    margin: auto;
    margin-bottom: 25px;

    @media (min-width: $sm) {
      width: 47.5%;
      margin-right: 5%;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      .top {
        height: 315px;
        background-size: cover;
        background-position: center;
      }
    }

    @media (min-width: $lg) {
      width: 22%;
      margin-right: 4%;
      margin-bottom: 0;

      &:nth-of-type(2) {
        margin-right: 4%;
      }
      &:nth-of-type(4) {
        margin-right: 0;
      }
    }

    .top {
      height: 315px;
      background-size: cover;
      background-position: center;
    }
    .bottom {
      height: 100px;
      background: $purple;

      .text {
        height: 75px;
      }
    }

    .button {
      border: 2px solid $magenta;
      margin-top: -25px;
    }
  }
}
</style>