<template>
  <div class="wrapper md:px-8 xl:px-0 pb-12 event-addons" :class="content.cssClass">
    <h5 class="section-heading blue opacity-0" v-animate-onscroll="'animated fadeIn'">Optional Add-Ons</h5>
    <div class="flex flex-wrap justify-center pt-4 -mb-2">
      <div class="experience-card add-on opacity-0" v-animate-onscroll="'animated fadeIn'" v-for="card in content.children" :key="card.index">
        <default-picture v-if="card.fields.icon" :defaultImg="card.fields.icon.fields.file.url" :description="card.fields.icon.fields.description" />
        <h5>{{card.fields.title}}</h5>
        <p>{{card.fields.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultPicture from '~/components/common/DefaultPicture'

export default {
  components: {
    DefaultPicture
  },
  props: [
    'content'
  ]
}
</script>