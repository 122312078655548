<template>
  <div class="card-section max-w-4xl mx-auto flex flex-col items-center justify-center">
    <h1 class="uppercase font-decimal text-3xl font-black text-center mb-1" v-html="content.heading"/>
    <body-copy class="w-2/3 font-decimal text-center text-xs" :content="content.body.content" />
    <div class="flex items-start justify-between flex-wrap mt-4">
      <div class="card flex flex-col items-center justify-center" v-for="(item, i) in content.children" :key="i">
        <img :src="item.fields.image.fields.file.url" :alt="item.fields.title">
        <h3 class="w-2/3 uppercase font-decimal text-base font-black text-center my-3" v-html="item.fields.title"/>
        <body-copy class="font-decimal text-center text-xs" :content="item.fields.body.content" />
      </div>
    </div>
    <nuxt-link class="button purple pulse" to="/book/seasonpass">Buy Now</nuxt-link>
  </div>
</template>

<script>
import BodyCopy from '~/components/common/BodyCopy'

export default {
  components: {
    BodyCopy
  },
  props: [
    'content',
  ]
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.card-section {
  padding: 30px;

  @media (min-width: $md) {
    padding: 50px 30px;
  }

  .card {
    width: 100%;

    @media (min-width: $sm) {
      width: 31.5%
    }

    img {
      width: 150px;
      height: auto;
    }
  }
}
</style>