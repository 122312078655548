<template>
  <div class="flex relative z-10 flex-col items-center justify-center pt-8" :class="content.cssClass">
      <img v-if="lockup" class="m-0" :src="lockup" :alt="content.heading">
      <h1 class="text-2xl" v-if="!lockup" v-html="content.heading"/>
      <div class="benefits-container w-full md:w-2/3 flex flex-wrap items-center justify-between pb-0 my-4 pt-8">
        <div @click="hovered = c.fields.title" @mouseenter="hovered = c.fields.title" @mouseleave="hovered = null" v-for="c in content.children" :key="c.index" class="m-auto content flex flex-col items-center justify-center">
          <img class="mb-2 md:mb-4" :src="c.fields.image.fields.file.url" :alt="c.fields.image.fields.title">
          <h2 class="text-center text-small" v-html="c.fields.title"/>
          <pre v-html="c.index"/>
          <div v-show="hovered === c.fields.title" class="hover-text absolute flex items-center justify-center">
            <body-copy @click="hovered = null" class="relative top-0 left-0 text-center text-white text-xs md:text-small mt-2 mb-0" :content="c.fields.body.content" />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    BodyCopy
  },
  props: [
    'content',
  ],
  data() {
    return {
      hovered: null,
    }
  },
  mounted() {
    console.log(this.content)
  },
  computed: {
    lockup() {
      if (this.content.body) {
        return this.content.body.content[0].data.target.fields.file.url
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.pink-orange {
  background: transparent;
  background-image: url('https://images.ctfassets.net/3dar4x4x74wk/6mufUzjAIs6liPopEKsYfU/4ea66eaffdf2f730a1856229abab009d/pink-orange-gradient.jpg') !important;
  background-size: cover;
  background-position: center;

  h1 {
    color: white;
  }

  .benefits-container {
    .content {
      h2, div {
        color: white;
      }
      .hover-text {
        background: black!important;

        p {
          margin: 0!important;
        }
      }
    }
  }
}

.benefits-section, .additional-perks {
  padding: 50px;
  background-position: top;

  @media (min-width: $md) {
    padding: 75px;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  .benefits-container {
    flex-direction: column;
    @media (min-width: $md) {
      flex-direction: row;
    }
    .content {
      width: 60%;
      margin: 10px 0;
      @media (min-width: $md) {
        width: 33%;
        min-height: 275px;
      }

      .hover-text {
        background-image: url('https://images.ctfassets.net/3dar4x4x74wk/6mufUzjAIs6liPopEKsYfU/4ea66eaffdf2f730a1856229abab009d/pink-orange-gradient.jpg');
        background-size: cover;
        background-position: center;
        max-width: 300px;
        min-height: 225px;
        border-radius: 10px;
        padding: 30px;

        @media (min-width) {
          min-height: 300px;
        }
      }
    }
  }
}
</style>