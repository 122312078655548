<template>
    <div class="relative hero" :class="content.cssClass">
      <video-background
        v-if="content.backgroundDesktop.fields.file.contentType == 'video/mp4'"
        :src="content.backgroundDesktop.fields.file.url"
        :sources="[
          {src: content.backgroundMobile.fields.file.url, res: 640, autoplay: true}
        ]"
      >
      </video-background>
      <default-picture
        v-else
        :sizes="[
          {
            url: bgMobile,
            break: 640
          }
        ]"
        :defaultImg="bgDesktop"
        :description="descriptionMobile" />
      <div class="absolute inset-0 flex items-center justify-center p-4">
        <h1 class="animated fadeIn lockup" :class="content.label ? 'label' : ''">
          {{content.label}}
          <default-picture v-if="imageTextMobile"
            :sizes="[
              {
                url: imageTextMobile,
                break: 640
              }
            ]"
            :defaultImg="imageTextDesktop"
            :description="imageTextMobileDescription"
            :cssClass="`transform ${this.$route.name.includes('careers') ? 'scale-75 md:scale-50 origin-center' : 'origin-left'}`"/>
        </h1>
      </div>
    </div>
</template>

<script>
import DefaultPicture from '~/components/common/DefaultPicture'

export default {
  components: {
    DefaultPicture,
  },
  props: [
    'content'
  ],
  computed: {
    imageTextDesktop() {
      return this.content.imageTextDesktop ? this.content.imageTextDesktop.fields.file.url : undefined
    },
    bgMobile() {
      return this.content.backgroundMobile ? this.content.backgroundMobile.fields.file.url : undefined
    },
    descriptionMobile() {
      return this.content.backgroundMobile ? this.content.backgroundMobile.fields.description : undefined
    },
    imageTextMobile() {
      return this.content.imageTextMobile ? this.content.imageTextMobile.fields.file.url : undefined
    },
    imageTextMobileDescription() {
      return this.content.imageTextMobile ? this.content.imageTextMobile.fields.description : undefined
    },
    bgDesktop() {
      return this.content.backgroundDesktop ? this.content.backgroundDesktop.fields.file.url : undefined
    },
    descriptionMobile() {
      return this.content.backgroundDesktop ? this.content.backgroundDesktop.fields.description : undefined
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.hero {
  width: 100vw;
  max-height: 600px;
  overflow: hidden;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8px;
    background-size: contain;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/6c4Ve31tKBNyjt5Wu8XmGq/4939eff850c8c3cb4e7269b4ae6dc568/pink-gradient-bar.png');

    @media (min-width: 1900px) {
      background-size: cover;
    }
  }

  .label {
    background: $purple;
    color: #fff;
    font-family: "urw-din-condensed";
    text-transform: uppercase;
    font-weight: 800;
    height: 53px;
    display: flex;
    align-items: center;
    padding: 0 15px 0 17px;
    position: absolute;
    bottom: 50px;
    left: 0;
    max-width: 210px;
    line-height: 1.1;

    &:after {
      max-width: 210px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $purple;
      transform-origin: 0 100%;
      transform: skew(-32deg);
      z-index: -1;
    }
  }
}

.hero-default {
  overflow: hidden;

  // h1 {
  //   position: absolute;
  //   left: 30%;
  //   top: 30%;
  // }

  // @media (max-width: $sm) {
  //   h1 {
  //     left: 4vw;
  //     top: 27%;

  //     img {
  //       width: 70vw;
  //       min-width: 340px;
  //       transform-origin: left;
  //     }
  //   }
  // }

  // @media (max-width: 375px) {
  //   h1 {
  //     top: 22%;
  //     left: 3vw;
  //   }
  // }
}

.careers-hero {
  position: fixed;
  div {
    justify-content: center;
    align-items: center;
  }
}

.season-pass-hero {
  max-height: 550px;
  @media (min-width: 640px) {
    max-height: 650px;
  }
  h1 {
    max-width: 525px;
    filter: drop-shadow(0 0 0.75rem #000);
  }
}

.hero-alt {
  &::after {
    display: none;
  }
}

.hero-video {
  @apply relative overflow-hidden max-h-screen;

  &:before {
    @apply block w-full;
    content: '';
    padding-top: calc((1 / 1) * 100%);

    @media (min-width: $md) {
      padding-top: calc((9 / 16) * 100%);
    }

  }

  .vue-responsive-videobg {
    @apply absolute top-0 left-0 w-full h-full;
  }
}
</style>
