<template>
  <section class="location-safe-way wrapper mx-auto px-3 md:px-8 xl:px-0 text-center">
    <img :src="content.backgroundImageDesktop.fields.file.url" :alt="content.backgroundImageDesktop.fields.description" class="opacity-0" v-animate-onscroll="'animated fadeIn'"/>
    <div class="opacity-0 delay-100" v-animate-onscroll="'animated fadeIn'">
    <nuxt-link :to="content.cta[0].fields.url" class="button purple pulse inline-flex mx-auto">{{content.cta[0].fields.title}}</nuxt-link>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>