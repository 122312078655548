<template>
  <div class="wrapper mx-auto px-2 md:px-8 xl:px-0 mx-auto flex flex-wrap opacity-0 pb-10 article-card-holder" v-animate-onscroll="'animated fadeIn'" :class="content.cssClass">
    <h2 v-if="content.title" v-html="content.title" class="section-heading purple opacity-0" v-animate-onscroll="'animated fadeIn'"/>
    <div class="flex flex-wrap -mx-1 sm:-mx-2 mb-4 justify-center animate-cards">
      <div v-for="(a,index) in content.children" :key="a.index" class="w-1/2 md:w-1/3 lg:w-1/4">
        <article-card
          v-if="a.fields && a.metadata.tags.length > 0 && a.metadata.tags[0].sys.id === 'news'"
          :btnText="'Learn More'"
          :href="`/news/${a.fields.slug}/`"
          :title="a.fields.title"
          :date="a.fields.date"
          :blurb="a.fields.excerpt"
          :linkImage="a.fields.linkImage ? a.fields.linkImage.fields.file.url : ''"
          :linkImageAlt="a.fields.linkImage ? a.fields.linkImage.fields.description : ''" />
        <article-card
          v-else
          :btnText="'Learn More'"
          :href="`/stories/${a.fields.slug}/`"
          :title="a.fields.title"
          :date="a.fields.date"
          :blurb="a.fields.excerpt"
          :linkImage="a.fields.linkImage ? a.fields.linkImage.fields.file.url : ''"
          :linkImageAlt="a.fields.linkImage ? a.fields.linkImage.fields.description : ''" />
      </div>
    </div>
    <nuxt-link v-if="content.cta" :to="content.cta[0].fields.url" class="mx-auto opacity-0" v-animate-onscroll="'animated fadeIn'"><button class="pulse purple block">{{content.cta[0].fields.title}}</button></nuxt-link>
  </div>
</template>

<script>
import ArticleCard from './ArticleCard'

export default {
  data() {
    return {
      minHeight: ''
    }
  },
  components: {
    ArticleCard
  },
  props: [
    'content'
  ],
  mounted() {
    window.addEventListener("resize", this.resizeArticleCards)
    this.$nextTick(() => {
      this.resizeArticleCards()
    })
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeArticleCards)
  },
  methods: {
    resizeArticleCards() {
      let cards = document.querySelectorAll('.article-card-body')
      let i
      for (i = 0; i < cards.length; i++) {
        cards[i].removeAttribute('style')
        if (i == 0) {
          this.minHeight = cards[i].offsetHeight
        } else {
          if (cards[i].offsetHeight > cards[i-1].offsetHeight) {
            if (cards[i].offsetHeight > this.minHeight) {
              this.minHeight = cards[i].offsetHeight
            }
          }
        }
      }
      for (i = 0; i < cards.length; i++) {
        cards[i].style.minHeight = this.minHeight + 'px'
      }
      if (this.minHeight < 180) {
        this.minHeight = 180
      }
    },
  }
}
</script>