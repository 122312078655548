<template>
  <section :class="content.cssClass" class="story-bg z-10 relative" :style="`background: url(` + content.backgroundImageDesktop.fields.file.url + `);`">
    <div v-if="content.body" :class="content.type" class="pt-12 flex p-10 items-center justify-center m-auto text-white text-center w-full md:w-2/3">
      <body-copy :content="content.body.content"/>
    </div>
  </section>
</template>

<script>
import DefaultButton from '../common/DefaultButton.vue'
import DefaultPicture from '../common/DefaultPicture'
import BodyCopy from '../common/BodyCopy'

export default {
  components: {
    DefaultButton,
    DefaultPicture,
    BodyCopy,
  },
  props: [
    'content'
  ],
  computed: {
    images() {
      return this.content.backgroundImageMobile ? [
        {
          url: this.content.backgroundImageMobile.fields.file.url,
          break: 992
        }
      ] : []
    },
    defaultImage() {
      return this.content.backgroundImageDesktop ? this.content.backgroundImageDesktop.fields.file.url : ''
    },
    alt() {
      return this.content.backgroundImageMobile ? this.content.backgroundImageMobile.fields.description : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.story-bg {
  background-size: cover!important;
  background-position: center!important;
}
</style>
