<template>
<div v-html="markdown" />
</template>

<script>
export default {
  props: [
    'content'
  ],
  computed: {
    markdown() {
      // Replace any/all links with a tags
      let content = this.content.replace(/\[(.*?)\]\((.*?)\s\"(.*?)\"\)/g, '<a href="$2" title="$3" class="underline">$1</a>')
      return content
    }
  }
}
</script>