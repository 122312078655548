<template>
  <div class="lines-bg" :id="slug" :class="slug">
    <div v-show="page.fields.showLanguageToggle" class="toggle absolute flex w-full items-center justify-start cursor-pointer px-10 py-4 bg-black" v-on:click="toggleLanguage()">
      <div class="mr-2 text-xs uppercase text-white">English</div>
      <div class="contrast-toggle border border-white rounded relative w-10 h-3">
        <div :style="{left: languageToggle ? 'calc(100% - 1.5rem)' : '0'}" :class="`contrast-switch border border-white rounded bg-white text-black absolute flex items-center justify-center w-6 h-3`">{{ languageToggle ? 'On' : 'Off'}}</div>
      </div>
      <div class="ml-2 text-xs uppercase text-white">Spanish</div>
    </div>
    <page-sections v-if="children.length > 0" :sections="children" />
    <div class="no-sections" v-if="children.length === 0" />
    <div class="max-w-2xl px-6 pt-6 sm:pt-24 lg:pt-8 pb-12 lg:pb-20 mx-auto opacity-0" v-if="body.length > 0" v-animate-onscroll="'animated fadeIn'">
      <body-copy :content="body" />
    </div>
  </div>
</template>

<script>
import PageSections from '~/components/PageSections'
import BodyCopy from '~/components/common/BodyCopy'

export default {
  components: {
    PageSections,
    BodyCopy
  },
  data() {
    return {
      // languageToggle: false,
    }
  },
  async validate({ $api, params }) {
    return await $api.getPage(params.slug)
  },
  async asyncData({ $api, params }) {
    return {
      page: await $api.getPage(params.slug)
    }
  },
  head() {
    return this.$metadata(this.page)
  },
  mounted() {
    // Code to hide spanish components on Brownsville NCO on load
    let spanish = document.getElementsByClassName('spanish')

    for (let i = 0; i <spanish.length; i++) {
      spanish[i].classList.add('hidden')
    }
  },
  computed: {
    slug() {
      return this.page.fields.slug
    },
    body() {
      return this.page.fields.body ? this.page.fields.body.content : []
    },
    children() {
      return this.page.fields.children ? this.page.fields.children : []
    },
    languageToggle() {
      return this.$store.state.languageToggle
    }
  },
  methods: {
    toggleLanguage() {
      // Code to handle Brownsville English/Spanish language toggle
      this.$store.commit('setLanguageToggle', this.languageToggle ? false : true)
      // this.languageToggle = !this.languageToggle
      console.log('Spanish: ', this.languageToggle)

      let english = document.getElementsByClassName('english')
      let spanish = document.getElementsByClassName('spanish')

      if (this.languageToggle === true) {
        for(var i = 0; i < english.length; i++) {
          english[i].classList.add('hidden')
        }

        for(var i = 0; i < spanish.length; i++) {
          spanish[i].classList.remove('hidden')
        }
      } else {
        for(var i = 0; i < english.length; i++) {
          english[i].classList.remove('hidden')
        }
        for(var i = 0; i < spanish.length; i++) {
          spanish[i].classList.add('hidden')
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/_variables';
#lubbock-texas-offer-jan-2021 {
  em {
    font-size: 14px;
    margin-top: 44px;
    display: block;
  }
}

.pi-day-challenge {
  background-image: none;
  background: $purple;
}

.brownsville {
  .toggle {
    top: 50px;
    transition: all .3s ease;

    @media (min-width: $sm) {
      top: 65px;
    }
    
    @media (min-width: 1170px) {
      top: 117px;
    }
  }
}
</style>
