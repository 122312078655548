<template>
  <div class="wrapper mx-auto px-2 md:px-8 xl:px-0" :class="content.cssClass">
    <div class="event-subcategories" :id="content.heading.split(' ').join('-').toLowerCase()">
      <h2 v-html="content.heading" />
      <div class="event-subcategories-wrapper">
        <event-subcategory v-for="event in content.children" :key="event.index" :content="event.fields" />
      </div>
      <div v-if="content.cssClass != 'birthday-packages'" class="text-center mt-8">
        <nuxt-link class="button more pulse purple" :to="'/events/#'+type">{{ content.cssClass === 'holiday-events' ? 'More Details' : 'View All'}}</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import EventSubcategory from './EventSubcategory'

export default {
  components: {
    EventSubcategory
  },
  props: [
    'content'
  ],
  data() {
    return {
      'type': ''
    }
  },
  mounted() {
    if (process.client) {
      this.type = $nuxt.$route.path.split('/')[2]
    }
  }
}
</script>

<style lang="scss" scoped>
.birthday-packages {
  margin-bottom: 25px;

  @media (min-width: 600px) {
    margin-bottom: 50px;
  }
}

.holiday-events {
  .event-subcategories {
    padding: 20px 0;
    h2 {
      display: none;
    }

    > div:last-of-type {
      display: none;
    }
  }
}
</style>