<template>
  <section>
    <div class="wrapper mx-auto px-2 md:px-8 xl:px-0 pb-1 opacity-0" v-animate-onscroll="'animated fadeIn'">
      <h3 class="section-heading purple">{{content.heading}}</h3>
    </div>
    <div class="wrapper mx-auto px-0 md:px-8 xl:px-0 pb-6 opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'">
      <slider class="video-slider" v-bind="settings" @afterChange="handleChange">

        <div class="embed-video-wrapper" v-for="(video, index) in content.videos">
          <div class="embed-container">
            <iframe :id="video.sys.id" :src="video.fields.value+'?byline=false&portrait=false&title=false'" frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
          </div>
        </div>

        <template #prevArrow>
          <button class="purple pulse">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="11px" height="22px" viewBox="0 0 11 22" enable-background="new 0 0 11 22" xml:space="preserve">
            <polyline fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
              10,1 1,11 10,21 "/>
            </svg>
          </button>
        </template>
        <template #nextArrow>
         <button class="pulse purple">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="11px" height="22px" viewBox="0 0 11 22" enable-background="new 0 0 11 22" xml:space="preserve">
            <polyline fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
              1,21 10,11 1,1 "/>
            </svg>
          </button>
        </template>
      </slider>
    </div>
  </section>
</template>

<script>
import Slider from 'vue-slick-carousel'

export default {
  components: {
    Slider,
  },
  props: [
    'content'
  ],
  data: function() {
    return {
      settings: {
        arrows: true,
        adaptiveHeight: true,
        autoplay: false,
        slidesToShow: 1,
        centerMode: true,
        infinite: true,
        variableWidth: true,
      }
    }
  },
  methods: {
    handleChange() {
      for(var i = 0; i < document.querySelectorAll('.slick-slide a').length; i++) {
        let x = document.querySelectorAll('.slick-slide a')[i].getBoundingClientRect().x
        if(x < window.innerWidth && x > 0) {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = 0
        } else {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = -1
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.embed-video-wrapper {
  height: 505px;
  width: 898px!important;
  padding: 0 15px;

  @media (max-width: $lg) {
    height: auto;
    width: 500px!important;
    padding: 0 15px;
  }

  @media (max-width: 590px) {
    width: 85vw!important;
    padding: 0 15px;
  }
}

.video-slider {
  position: relative;
  overflow: hidden;

  &:before, &:after {
    position: absolute;
    content: '';
    box-shadow: 60px -20px 20px 9px rgb(255,255,255);
    left: -80px;
    height: 200%;
    width: 50px;
    z-index: 1;
    top: 0;

    @media (max-width: $sm) {
      display: none;
    }
  }

  &:after {
    left: unset;
    box-shadow: -60px -20px 20px 9px rgb(255,255,255);
    right: -80px;
  }

  .slick-next,.slick-prev {
    position: absolute;
    cursor: pointer;
    top: 50%;
    margin-top: -36px;
    height: 72px;
    width: 50px;
    z-index: 2;
    padding: 0;

    &:hover {
      background: $purple;
    }
  }

  .slick-next {
    right: 0;
  }
}
</style>