<template>
  <div class="wrapper mx-auto px-2 md:px-8 xl:px-0 mx-auto pb-10">
    <div v-if="availableActivities.length > 0" class="flex flex-wrap justify-end">
      <h3 class="section-heading opacity-0" v-animate-onscroll="'animated fadeIn'">Experiences at {{location.centerName ? location.centerName : 'Main Event'}}</h3>
      <div class="w-full">
        <experience-carousel :slides="availableActivities" />
      </div>
    </div>
  </div>
</template>

<script>
import ExperienceCarousel from './ExperienceCarousel'

export default {
  components: {
    ExperienceCarousel
  },
  props: [
    'content'
  ],
  computed: {
    location() {
      return this.$store.state.location
    },
    availableActivities() {
      let all = []
      this.content.children.map(c => {
        if(!this.$store.state.location || !this.$store.state.location.venues || this.$store.state.location.venues[c.fields.identifier] == true) {
          let slider = Object.assign({}, c.fields.slider)
          slider.fields.cta = [
            {
              fields: {
                title: 'Learn More',
                url: `/experiences/${c.fields.slug}/`,
                internal: true
              }
            }
          ]
          all.push(slider)
        }
      })
      return all
    }
  }
}
</script>