<template>
  <div v-show="showLuckModal" class="luck-modal w-full flex flex-col items-center justify-center">
    <div class="luck-content flex flex-col items-center justify-center">
      <img class="lockup" src="https://images.ctfassets.net/3dar4x4x74wk/3HAQsH1yJJS7OLtFiVhhpV/06380283a320a96d04169f02ed63427e/luck-lockup-v3-mobile.png" alt="Welcome to the land of Luck">
      <div class="spacer"/>
      <h1 class="uppercase text-white text-center font-bold">Try Your Luck at Main Event</h1>
      <button @click="hideModal" class="my-4">Enter Site</button>
      <p class="uppercase text-white text-center font-thin">Participating locations through 10/4/22. © 2022 Apple Inc. &amp; Skydance Animation, LLC</p>
    </div> 
    <img class="luck-characters" src="https://images.ctfassets.net/3dar4x4x74wk/3zOu2loPDFW6byXopTL612/a3241f2d1b721ecc5f6c321b14444e48/luck-lockup-v3.png" alt="Welcome to the land of Luck">
    <img class="luck-rating" src="https://images.ctfassets.net/3dar4x4x74wk/40qf6gcqxX3EoFPEIasgKQ/2e958874b1fd76eb1f45896d554c4f51/luck-stream-rating.png" alt="Watch Luck on Apple TV+, Rated G">
  </div>
</template>

<script>

export default {  
  mounted() {
    if (process.browser) {
      let main = document.getElementsByTagName('main')
      let footer = document.getElementsByTagName('footer')
      main[0].className = 'hidden'
      footer[0].className = 'hidden'
    }
  },
  computed: {
    showLuckModal() {
      return this.$store.state.luckModal
    }
  },
  methods: {
    hideModal() {      
      let main = document.getElementsByTagName('main')
      let footer = document.getElementsByTagName('footer')
      main[0].className = 'flex items-stretch justify-between'
      footer[0].className = ''
      this.$store.commit('setLuckModal', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.luck-modal {
  // position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background: transparent;
  background-image: url('https://images.ctfassets.net/3dar4x4x74wk/1u3mhr2S4mtmYLHJVTcS1u/05103fa51029718dc1abfd9f12847354/website-takeover-bg.jpg')!important;
  background-size: cover;
  background-position: center;
  z-index: 99999;
  padding: 30px;
  overflow: scroll;

  @media (min-width: 800px) {
    height: 100vh;
    min-height: 900px;
    overflow-y: scroll;
  }

  .luck-rating {
    max-width:75px;
    position: absolute;
    right: 30px;
    bottom: 225px;
    z-index: 9;

    @media (min-width: $lg) {
      max-width: 100px;
      bottom: 100px;
      right: 100px;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  .luck-characters {
    position: absolute;
    width: 800px;
    max-width: 90%;
    z-index: 9;

    @media (min-width: $md) {
      transform: translateY(-50px);
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  .luck-content {
    position: relative;
    z-index: 9;
    flex: 1;

    .lockup {
      width: 90%;
      margin-bottom: 10px;
      // max-width: 300px;

      @media (min-width: $sm) {
        display: none;
        margin-bottom: 0;
      }
    }

    .spacer {
      flex-grow: 1;

      @media (max-width: $sm) {
        display: none;
      }
    }

    h1 {
      letter-spacing: 2.2px;
      font-size: 32px;
      line-height: 132%;
      filter: drop-shadow(0 0 0.75rem #333);
      @media (min-width: $md) {
        font-size: 40px;
      }
    }

    button {
      background: #5728a8;
      color: #3db839;
      width: 100%;

      @media (min-width: $md) {
        height: 60px;
        width: 150px;
      }
    }

    p {
      font-size: 10px;
      width: 50%;
    }
  }
}
</style>