<template>
  <div class="relative outline-none" :class="fields.cssClass ? fields.cssClass : ''">
    <div class="px-4 lg:pr-0 lg:pl-64 delay-400 opacity-0" v-animate-onscroll="'animated fadeIn'">
      <default-picture
        v-if="!fields.video && fields.imageMobile.fields.file && fields.imageDesktop.fields.file "
        class="feature-slide-image"
        :sizes="[
          {
            url: fields.imageMobile ? fields.imageMobile.fields.file.url : '',
            break: 992
          }
        ]"
        :defaultImg="fields.imageDesktop ? fields.imageDesktop.fields.file.url : ''" 
        :description="fields.imageMobile ? fields.imageMobile.fields.description : ''" />
        <div class="video-wrapper" v-if="fields.video && fields.video.fields  && fields.video.fields.file">
          <video playsinline autoplay muted loop>
              <source :src="fields.video.fields.file.url" type="video/mp4"/>
          </video>
        </div>
    </div>

    <div class="feature-slide-text-holder flex -mt-8 lg:absolute lg:inset-0 lg:mt-0 lg:px-0 lg:items-center outline-none delay-200 opacity-0" v-animate-onscroll="'animated fadeIn'">
      <div class="feature-slide-text text-white text-center p-8 w-full max-w-lg" :class="fields.textBackground ? String(fields.textBackground).split(' ').join('-').toLowerCase() : ''">
        <body-copy :content="fields.body && fields.body.content ? fields.body.content : ''" />
        <div class="delay-600 opacity-0" v-animate-onscroll="'animated fadeIn'">
          <div class="pulse" v-if="fields.cta">
            <default-button
              v-for="cta in fields.cta"
              :key="cta.index"
              :title="cta.fields.title"
              :url="cta.fields.url ? cta.fields.url : ''"
              :internal="cta.fields.internalLink" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from './DefaultButton'
import DefaultPicture from './DefaultPicture'
import BodyCopy from './BodyCopy'

export default {
  components: {
    DefaultButton,
    DefaultPicture,
    BodyCopy
  },
  props: [
    'fields'
  ]
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.feature-slide-text-holder {
  transform: translate3d(0, 0, 1px);
}

.single-slide {
  .feature-slide-text {
    text-align: left;
    padding: 35px 40px;
    width: 500px;
    max-width: 100%;
    > div {
      margin: 0;
    }
    img {
      margin-left: 0px;
      @media (max-width: $sm) {
        // margin: auto;
      }
    }
    p {
      margin-left: 0px;
      font-size: 16px;
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
}

.pc-birthday {
  .feature-slide-text-holder {
    .feature-slide-text {
      background: white;
    }
  }
}

.luck-birthday {
  .feature-slide-text-holder {
    .feature-slide-text {
      background: #3bb93b;
    }
  }
}
</style>