<template>
  <div class="event-packages" :class="content.cssClass">
      <div class="cats wrapper mx-auto px-3 md:px-10 xl:px-1 mx-auto flex flex-wrap opacity-0 pb-6" v-animate-onscroll="'animated fadeIn'">
        <div class="overflow-x-scroll w-full flex">
          <button class="mr-2 whitespace-no-wrap" @click="showAll" :class="activeSubCat == 'All' ? 'magenta' : ''">All Events</button>
          <button class="mr-2 whitespace-no-wrap" @click="filterCategory(category)" :class="activeCat == category && activeSubCat == '' ? 'magenta' : ''" v-for="category in eventCategories" :key="category.index" :id="category.toString().split(' ').join('-').toLowerCase()">{{category}}</button>
        </div>
        <div class="overflow-x-scroll w-full flex pt-2">
          <button class="mr-2 whitespace-no-wrap" @click="filterSubCategory(subCat)" :class="activeSubCat == subCat ? 'magenta' : ''" v-for="subCat in eventSubcategories" :key="subCat.index" :id="subCat.toString().split(' ').join('-').toLowerCase()">{{subCat}}</button>
        </div>
      </div>

      <div id="all" v-if="activeSubCat == 'All'" class="wrapper mx-auto px-2 md:px-8 xl:px-0 flex flex-wrap opacity-0 pb-10 article-card-holder" v-animate-onscroll="'animated fadeIn'">
        <div class="article-card-wrapper">
          <div class="flex flex-wrap -mx-1 sm:-mx-2 mb-4 w-full animate-cards">
            <div v-for="event in events" :key="event.index" class="w-1/2 md:w-1/3 lg:w-1/4">
              <article-card
              :linkImage="event.fields.linkImage ? event.fields.linkImage.fields.file.url : ''"
              :linkImageAlt="event.fields.linkImage ? event.fields.linkImage.fields.description : ''"
              :title="event.fields.title"
              :blurb="event.fields.description"
              btnText="Learn More"
              :href="'/events/'+event.fields.eventType[0].fields.slug+'/'+event.fields.slug+'/'"/>
            </div>
          </div>
        </div>
      </div>

      <div v-for="category in eventCategories" :key="category.index" v-if="activeCat == category" :id="category.toString().split(' ').join('-').toLowerCase()"  class="wrapper mx-auto px-2 md:px-8 xl:px-0 mx-auto flex flex-wrap opacity-0 pb-10 article-card-holder" v-animate-onscroll="'animated fadeIn'">
        <div class="article-card-wrapper">
          <div class="flex flex-wrap -mx-1 sm:-mx-2 mb-4 w-full animate-cards">
            <div v-for="event in events" :key="event.index" v-if="inCategory(event,category)" class="w-1/2 md:w-1/3 lg:w-1/4">
              <article-card
              :linkImage="event.fields.linkImage ? event.fields.linkImage.fields.file.url : ''"
              :linkImageAlt="event.fields.linkImage ? event.fields.linkImage.fields.description : ''"
              :title="event.fields.title"
              :blurb="event.fields.description"
              btnText="Learn More"
              :href="'/events/'+event.fields.eventType[0].fields.slug+'/'+event.fields.slug+'/'"/>
            </div>
          </div>
        </div>
      </div>

      <div v-for="subCat in eventSubcategories" :key="subCat.index" v-if="activeSubCat == subCat" :id="subCat.toString().split(' ').join('-').toLowerCase()"  class="wrapper mx-auto px-2 md:px-8 xl:px-0 mx-auto flex flex-wrap opacity-0 pb-10 article-card-holder" v-animate-onscroll="'animated fadeIn'">
        <div class="article-card-wrapper">
          <div class="flex flex-wrap -mx-1 sm:-mx-2 mb-4 w-full animate-cards">
            <div v-for="event in events" :key="event.index" v-if="inSubCategory(event,subCat)" class="w-1/2 md:w-1/3 lg:w-1/4">
              <article-card
              :linkImage="event.fields.linkImage ? event.fields.linkImage.fields.file.url : ''"
              :linkImageAlt="event.fields.linkImage ? event.fields.linkImage.fields.description : ''"
              :title="event.fields.title"
              :blurb="event.fields.description"
              btnText="Learn More"
              :href="'/events/'+event.fields.eventType[0].fields.slug+'/'+event.fields.slug+'/'"/>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import ArticleCard from '~/components/stories/ArticleCard'

export default {
  components: {
    ArticleCard
  },
  props: [
    'content',
    'referrer'
  ],
  data() {
    return {
      subCats: [],
      activeSubCat: 'All',
      activeCat: '',
      minHeight: ''
    }
  },
  mounted() {
    const buttons = document.querySelectorAll('button')
    let i = 0
    for (i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener("click", this.scroll);
    }

    window.addEventListener("resize", this.resizeArticleCards)
    this.$nextTick(() => {
      this.resizeArticleCards()
    })

    if (window.location.hash == '#corporate-events') {
      this.filterCategory('Corporate Events')
      window.dispatchEvent(new CustomEvent('scroll'))
    }
    if (window.location.hash == '#group-events') {
      this.filterCategory('Group Events')
      window.dispatchEvent(new CustomEvent('scroll'))
    }
    if (window.location.hash == '#school-events') {
      this.filterCategory('School Events')
      window.dispatchEvent(new CustomEvent('scroll'))
    }

    setTimeout(function() {
      let prev = localStorage.getItem("prev")
      if (prev == '/events/corporate-events') {
        this.filterCategory('Corporate Events')
        window.dispatchEvent(new CustomEvent('scroll'))
      }
      if (prev == '/events/group-events') {
        this.filterCategory('Group Events')
        window.dispatchEvent(new CustomEvent('scroll'))
      }
      if (prev == '/events/school-events') {
        this.filterCategory('School Events')
        window.dispatchEvent(new CustomEvent('scroll'))
      }
    }.bind(this), 100)

  },
  destroyed() {
    window.removeEventListener("resize", this.resizeArticleCards)

    const buttons = document.querySelectorAll('button')
    let i = 0
    for (i = 0; i < buttons.length; i++) {
      buttons[i].removeEventListener("click", this.scroll);
    }
  },
  methods: {
    resizeArticleCards() {
      let cards = document.querySelectorAll('.article-card-body')
      let i
      for (i = 0; i < cards.length; i++) {
        cards[i].removeAttribute('style')
        if (i == 0) {
          this.minHeight = cards[i].offsetHeight
        } else {
          if (cards[i].offsetHeight > cards[i-1].offsetHeight) {
            if (cards[i].offsetHeight > this.minHeight) {
              this.minHeight = cards[i].offsetHeight
            }
          }
        }
      }
      for (i = 0; i < cards.length; i++) {
        cards[i].style.minHeight = this.minHeight + 'px'
      }
      if (this.minHeight < 180) {
        this.minHeight = 180
      }
    },
    scroll() {
      window.dispatchEvent(new CustomEvent('scroll'))
    },
    getCategoryTitle(category) {
      return category.fields.title
    },
    getSubcategoryTitle(subCat) {
      return subCat.fields.title
    },
    getSubcategories(event) {
      if (event.fields.eventSubcategories) {
        return event.fields.eventSubcategories.map(this.getSubcategoryTitle)
      }
    },
    getCategories(event) {
      if(event.fields.eventType) {
        return event.fields.eventType.map(this.getCategoryTitle)
      }
    },
    inSubCategory(event, subCat) {
      let i = 0
      if (event.fields.eventSubcategories) {
        for (i = 0; i < event.fields.eventSubcategories.length; i++) {
          if (event.fields.eventSubcategories[i].fields.title == subCat) {
            return true
          }
        }
      }
      return false
    },
    inCategory(event, category) {
      let i = 0
      if (event.fields.eventType) {
        for (i = 0; i < event.fields.eventType.length; i++) {
          if (event.fields.eventType[i].fields.title == category) {
            return true
          }
        }
      }
      return false
    },
    filterCategory(category) {
      this.activeSubCat = ''
      this.activeCat = category
      setTimeout(function() {
        this.resizeArticleCards()
      }.bind(this), 100)
    },
    filterSubCategory(subCategory) {
      this.activeCat = ''
      this.activeSubCat = subCategory
      setTimeout(function() {
        this.resizeArticleCards()
      }.bind(this), 100)
    },
    showAll() {
      this.activeSubCat = 'All'
      this.activeCat = ''
      setTimeout(function() {
        this.resizeArticleCards()
      }.bind(this), 100)
    }
  },
  computed: {
    events() {
      let events = []
      this.content.children.map(e => {
        if(e) {
          events.push(e)
        }
      })
      return events
    },
    eventCategories() {
      try {
        return [...new Set(this.events.map(this.getCategories).flat())].sort().filter(Boolean)
      } catch(err) {
        console.error(err)
        return []
      }
    },
    eventSubcategories() {
      try {
        return [...new Set(this.events.map(this.getSubcategories).flat())].sort().filter(Boolean)
      } catch(err) {
        console.error(err)
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.hide-cats {
  .cats {
    display: none;
  }
}
</style>