<template>
  <section class="delivery-menu wrapper mx-auto px-3 md:px-8 xl:px-0">

    <transition enter-active-class="animated fadeIn" leave-active-class="animated slideOutDown">
    <div id="delivery-locations-modal" v-if="showModal">
      <div class="delivery-locations-modal-body">
        <div class="delivery-locations-modal-form">
          <p class="uppercase text-2xl sm:text-5xl font-bold text-center text-white tracking tracking-wide leading-7 sm:leading-10">Let’s point you in the right direction.</p>
          <label class="block text-xl">Find your center</label>
          <select v-model="state" @change="showCity">
            <option selected disabled>State</option>
            <option>Arizona</option>
            <option>Colorado</option>
            <option>Delaware</option>
            <option>Florida</option>
            <option>Georgia</option>
            <option>Illinois</option>
            <!-- <option>Indiana</option> -->
            <option>Kansas</option>
            <option>Kentucky</option>
            <option>Louisiana</option>
            <option>Maryland</option>
            <option>Missouri</option>
            <option>New Mexico</option>
            <option>Ohio</option>
            <option>Oklahoma</option>
            <option>Tennessee</option>
            <option>Texas</option>
          </select>

          <select v-if="showCenters || state == 'Arizona'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="3">Avondale</option>
            <option value="3">Gilbert</option>
            <option value="3">Tempe</option>
          </select>

          <select v-if="showCenters || state == 'Colorado'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="3">Highlands Ranch</option>
          </select>

          <select v-if="showCenters || state == 'Florida'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="3">Orlando</option>
            <option value="2">Jacksonville</option>
            <option value="2">Wesley Chapel</option>
          </select>

          <select v-if="showCenters || state == 'Georgia'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="2">Alpharetta</option>
            <option value="2">Atlanta</option>
            <option value="2">Suwanee</option>
          </select>

          <select v-if="showCenters || state == 'Illinois'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="3">Hoffman Estates</option>
            <option value="3">Warrenville</option>
          </select>

          <select v-if="showCenters || state == 'Indiana'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="1">Indianapolis</option>
          </select>

          <select v-if="showCenters || state == 'Missouri'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="1">Independence</option>
            <option value="1">Kansas City North</option>
          </select>

          <select v-if="showCenters || state == 'Kansas'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="2">Olathe</option>
          </select>

          <select v-if="showCenters || state == 'Kentucky'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="2">Louisville</option>
          </select>

          <select v-if="showCenters || state == 'Louisiana'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="2">Baton Rouge</option>
          </select>

          <select v-if="showCenters || state == 'Maryland'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="3">Columbia</option>
          </select>

          <select v-if="showCenters || state == 'Delaware'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="3">Newark (Wilmington)</option>
          </select>

          <select v-if="showCenters || state == 'New Mexico'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="1">Albuquerque</option>
          </select>

          <select v-if="showCenters || state == 'Ohio'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="3">Avon</option>
            <option value="3">West Chester</option>
          </select>

          <select v-if="showCenters || state == 'Oklahoma'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="2">Oklahoma City</option>
            <option value="1">Tulsa</option>
          </select>

          <select v-if="showCenters || state == 'Tennessee'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="2">Knoxville</option>
            <option value="1">Memphis</option>
          </select>

          <select v-if="showCenters || state == 'Texas'" @change="setCenter" name="center">
            <option selected disabled>Center</option>
            <option value="2">Austin</option>
            <option value="1">Fort Worth South</option>
            <option value="2">Fort Worth North</option>
            <option value="2">Frisco</option>
            <option value="2">Grand Prairie</option>
            <option value="2">Grapevine</option>
            <option value="2">Humble</option>
            <option value="2">Katy</option>
            <option value="1">Laredo</option>
            <option value="1">Lewisville</option>
            <option value="1">Lubbock</option>
            <option value="1">Pharr</option>
            <option value="2">Plano</option>
            <option value="2">San Antonio (North)</option>
            <option value="2">San Antonio (West)</option>
            <option value="2">Shenandoah</option>
            <option value="2">Stafford</option>
            <option value="2">Webster</option>
          </select>

          <div class="button-wrapper">
            <button class="purple pulse" v-if="tier !== 'Center' && tier !== 0" @click="showModal = false">Submit</button>
          </div>
        </div>
      </div>
    </div>
    </transition>

    <div v-for="(child, index) in content.children" :key="index" v-if="child.sys.contentType.sys.id == 'json'">

      <h3 class="opacity-0" v-animate-onscroll="'animated fadeIn'" v-if="child.fields.value.title">{{child.fields.value.title}}</h3>
      <h4 class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'" v-if="child.fields.value.subtitle">{{child.fields.value.subtitle}}</h4>

      <div class="delivery-menu-section" v-for="(section, index) in child.fields.value.sections" :key="index" :id="section.id">
        <h4 class="font-brush opacity-0" v-animate-onscroll="'animated fadeIn'" v-if="section.title">{{section.title}}</h4>

        <div class="flex flex-wrap -mx-4">
          <!-- <menu-item class="opacity-0" v-animate-onscroll="'animated fadeIn'"  :title="item.name"  :price="item.price" :description="item.description" :items="item.subItems" :extra="{title:item.extraTitle,description:item.extraDescription}"/> -->
          <div class="menu-item opacity-0" v-animate-onscroll="'animated fadeIn'" v-if="section.items" v-for="(item, index) in section.items">
            <div class="flex">
              <div class="menu-item-title">{{item.name}}</div>
              <div class="menu-item-calories" v-if="item.calories">{{item.calories}}</div>
              <div class="menu-item-price" v-if="item.price">{{item.price[tier-1]}}</div>
            </div>
            <div class="menu-item-description" v-if="item.description">{{item.description}}</div>
            <div class="flex menu-item-subitems" v-for="(item, index) in item.items" :key="index">
              <div v-if="item.name" class="menu-item-subitem">{{item.name}}</div>
              <div v-if="item.price" class="menu-item-price">{{item.price[tier-1]}}</div>
              <div v-if="item.calories" class="menu-item-calories">{{item.calories}}</div>
            </div>
            <div class="menu-item-extra" v-if="item.extra">
              <div class="menu-item-description">{{item.extra}}</div>
            </div>
          </div>

          <div v-if="section.options" class="delivery-options opacity-0" v-animate-onscroll="'animated fadeIn'">
            <div>
              <div>
                <h4 v-if="section.options.title">{{section.options.title}}</h4>
                <span v-for="(item, index) in section.options.items">{{item}}</span>
              </div>
            </div>
          </div>
        </div>

        <p class="legal opacity-0" v-animate-onscroll="'animated fadeIn'" v-if="child.fields.value.legal">{{child.fields.value.legal}}</p>

      </div>

    </div>
    
  </section>
</template>

<script>
import MenuItem from '../menu/MenuItem.vue'

export default {
  data() {
    return {
      showModal: true,
      state: 'State',
      center: null,
      tier: 'Center',
      showCenters: false,
    }
  },
  components: {
    MenuItem
  },
  methods: {
    setCenter(e) {
      this.center = e.target.options[e.target.selectedIndex].text
      this.tier = e.target.options[e.target.selectedIndex].value
      this.$store.commit('setDeliveryCenter', this.center)
    },
    showCity() {
      const locationSelects = document.querySelectorAll('[name=center]')
      for (var i = 0; i < locationSelects.length; i++) {
        locationSelects[i].selectedIndex = 0
      }
      this.tier = 'Center'
    },
  },
  props: [
    'content'
  ]
}
</script>

<style lang="scss">
@import '../../assets/scss/_variables';

.delivery-menu-section#pizza {
  .menu-item {
    width: 50%;

    @media (max-width: $md) {
      width: 100%;
    }
  }
}

.delivery-menu-section#salads {
  .menu-item, .delivery-options {
    width: 40%;

    @media (max-width: $md) {
      width: 100%;
    }
  }

  .delivery-options {
    width: 60%;

    > div {
      padding-bottom: 20px;
    }

    span {
      margin-bottom: 10px;
      max-width: 140px;
    }
  }
}

.delivery-menu-section#drinks {
  .menu-item:nth-of-type(3) {
    width: 100%;
    margin-top: -25px;
  }

  .menu-item-extra div {
    font-style: italic;    
  }
}

.delivery-menu .legal {
  text-align: center;
  font-size: 17px;
  margin: 35px 0 60px;
}
</style>