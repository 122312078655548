<template>
  <div class="flex relative z-10 flex-col items-center justify-center" :class="content.cssClass">
      <img class="m-0" :src="lockup" :alt="content.heading">
      <div class="w-full md:w-2/3 flex items-center justify-between mt-8">
        <div class="flex items-center justify-center">
          <nuxt-link to="./opportunities/"  style="background-image: url('https://images.ctfassets.net/3dar4x4x74wk/7JDWuPjgBDeHuwsTqnDnQB/b19902e39c1d66a7f24fea7a4d4ae39d/careers.jpg');"/>
          <nuxt-link to="./culture/"  style="background-image: url('https://images.ctfassets.net/3dar4x4x74wk/WllIomYWOJcvJHBk4HOfu/c8f4ad7cbc5d13f16f4b1b4024b81607/culture.jpg');"/>
        </div>
        <div class="flex items-center justify-center">
          <nuxt-link to="./benefits/"  style="background-image: url('https://images.ctfassets.net/3dar4x4x74wk/Vd6tULSifYQSBLeuCgCIb/1a921c200f999b8d3a7aeb092a412175/benefits.jpg');"/>
          <nuxt-link to="./growth/" style="background-image: url('https://images.ctfassets.net/3dar4x4x74wk/3p7bu1Mk6AherUZHM4akHF/71d8a7ab8594d15c31a87a19f483a2b7/growth.jpg');"/>
        </div>
      </div>
  </div>
</template>

<script>
import AppLink from '~/components/common/AppLink'

export default {
  components: {
    AppLink
  },
  props: [
    'content',
  ],
  computed: {
    lockup() {
      return this.content.body.content[0].data.target.fields.file.url
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.mission-section {
  padding: 50px;
  background-position: top;

  @media (min-width: $md) {
    padding: 75px;
  }

  div {
    flex-direction: column;      
    @media (min-width: $md) {
      flex-direction: row;
    }
    div {
      width: 100%;
      flex-direction: column;
      @media (min-width: $md) {
        width: 49%;
      }

      &:nth-of-type(2) {
        flex-direction: column-reverse;
      }
      a{
        cursor: pointer;
        width: 100%;
        border-radius: 10px;
        height: 250px;
        margin: 5px 0;
        background-size: cover;
        background-position: center;
        @media (min-width: $md) {
          height: 425px;
        }

        &:nth-of-type(2) {
          background-size: cover;
          background-position: center;
          @media (min-width: $md) {
            height: 275px;
          }
        }
      }
    }
  }
}
</style>