<template>
  <section class="curbside-or-delivery wrapper mx-auto px-3 md:px-8 xl:px-0 pb-10">
    <img class="opacity-0" v-animate-onscroll="'animated fadeIn'" :src="content.children[0].fields.body.content[0].data.target.fields.file.url" :alt="content.children[0].fields.body.content[0].data.target.fields.description"/>
    <p class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.children[0].fields.body.content[1].content[0].value}}</p>

    <img class="opacity-0" v-animate-onscroll="'animated fadeIn'" :src="content.children[1].fields.body.content[0].data.target.fields.file.url" :alt="content.children[1].fields.body.content[0].data.target.fields.description"/>
    <p class="opacity-0 delay-200" v-animate-onscroll="'animated fadeIn'">{{content.children[1].fields.body.content[1].content[0].value}}</p>
    <div class="flex flex-wrap items-center justify-center">
      <div class="opacity-0" v-animate-onscroll="'animated fadeIn'">
        <img :src="content.children[2].fields.body.content[0].data.target.fields.file.url" :alt="content.children[2].fields.body.content[0].data.target.fields.description"/>
        <a target="_blank" :href="uberLink" class="pulse">{{content.children[2].fields.body.content[1].content[0].value}}</a>
      </div>
      <b class="opacity-0" v-animate-onscroll="'animated fadeIn'">OR</b>
      <div class="opacity-0" v-animate-onscroll="'animated fadeIn'">
        <img :src="content.children[2].fields.body.content[2].data.target.fields.file.url" :alt="content.children[2].fields.body.content[2].data.target.fields.description"/>
        <a target="_blank" :href="doordashLink" class="pulse">{{content.children[2].fields.body.content[3].content[0].value}}</a>
      </div>
    </div>

    <br/>

  </section>
</template>

<script>
export default {
  data() {
    return {
      doordashID: {
        'Albuquerque': '1102135',
        'Alpharetta': '512953',
        'Atlanta': '512969',
        'Austin': '513191',
        'Avon': '1102087',
        'Avondale': '513263',
        'Baton Rouge': '1102059',
        'Columbia': '1102051',
        'Frisco': '514857',
        'Laredo': '1102194',
        'Fort Worth North': '515150',
        'Fort Worth South': '513011',
        'Gilbert': '513245',
        'Grand Prairie': '1102485',
        'Grapevine': '515140',
        'Highlands Ranch': '1102229',
        'Hoffman Estates': '1102339',
        'Humble': '1102446',
        'Independence': '1102393',
        'Jacksonville': '1102254',
        'Kansas City North': '1102389',
        'Katy': '1102440',
        'Knoxville': '1102233',
        'Lewisville': '514914',
        'Louisville': '1102384',
        'Lubbock': '1102496',
        'Memphis': '1102417',
        'Newark (Wilmington)': '1102244',
        'Oklahoma City': '514598',
        'Olathe': '1102372',
        'Orlando': '1102302',
        'Pharr': '513173',
        'Plano': '514836',
        'San Antonio (North)': '513132',
        'San Antonio (West)': '513152',
        'Shenandoah': '1102475',
        'Stafford': '513061',
        'Suwanee': '1102309',
        'Tempe': '513232',
        'Tulsa': '138208',
        'Warrenville': '1102318',
        'Webster': '513109',
        'Wesley Chapel': '1102287',
        'West Chester': '1102489'
      }
    }
  },
  props: [
    'content'
  ],
  computed: {
    center() {
      return this.$store.state.deliveryCenter
    },
    doordashLink() {
      return 'https://www.doordash.com/store/'+this.doordashID[this.center]+'/?utm_source=partner-link&utm_medium=website&utm_campaign='+this.doordashID[this.center]
    },
    uberLink() {
      if (this.center == 'Newark (Wilmington)') return 'https://order.ubereats.com/philadelphia/food-delivery/main-event-newark/K1sefLc7SRmUJqEPJ044OA/'
      if (this.center == 'Grapevine') return 'https://order.ubereats.com/dallas/food-delivery/main-event-grapevine/Go5KMq_cTLmteAvKggB_oA/'
      if (this.center == 'West Chester') return 'https://order.ubereats.com/cincinnati/food-delivery/main-event-west-chester/tXxEd2-NSh-UXAEnAPPbkQ/'
      if (this.center == 'Louisville') return 'https://order.ubereats.com/louisville/food-delivery/main-event-louisville/JUg3c6lwRKGWA6NpohppUg/'
      if (this.center == 'Jacksonville') return 'https://order.ubereats.com/jacksonville/food-delivery/main-event-jacksonville/WnlFITuWS3S6U2olzS5kLA/'
      if (this.center == 'Lewisville') return 'https://order.ubereats.com/dallas/food-delivery/main-event-lewisville/VWCnjAncSYaVG1IEAHOECA/'
      if (this.center == 'San Antonio (North)') return 'https://order.ubereats.com/san-antonio/food-delivery/main-event-san-antonio-north/fUj5QR75QfeoeM-fo15Ehg/'
      if (this.center == 'Humble') return 'https://order.ubereats.com/houston/food-delivery/main-event-humble/pZagNGVBQ6OqUQQQ7tNBIQ/'
      if (this.center == 'Memphis') return 'https://order.ubereats.com/memphis/food-delivery/main-event-memphis/Im0BFUzvTuOj3SJDk6gCaQ/'
      if (this.center == 'Avon') return 'https://order.ubereats.com/cleveland/food-delivery/main-event-avon/hfzVx0mWT4mafyzqlKnChg/'
      if (this.center == 'Independence') return 'https://order.ubereats.com/kansas-city/food-delivery/main-event-independence/jhLLlo6nS4mQE3uy3UihKw/'
      if (this.center == 'Pharr') return 'https://order.ubereats.com/brownsville/food-delivery/main-event-pharr/KKXLOlm_RJOzw8J_cbRBHw/'
      if (this.center == 'Fort Worth South') return 'https://order.ubereats.com/dallas/food-delivery/main-event-fort-worth/9kq4mYuxQROQbeKt7PIcSg/'
      if (this.center == 'Grand Prairie') return 'https://order.ubereats.com/dallas/food-delivery/main-event-grand-prairie/HMBcTFITQQGWa7v9s_yEow/'
      if (this.center == 'Warrenville') return 'https://order.ubereats.com/chicago/food-delivery/main-event-warrenville/kCHH097rR0CTh_j090gbnw/'
      if (this.center == 'Baton Rouge') return 'https://order.ubereats.com/baton-rouge/food-delivery/main-event-baton-rouge/r2o4UAeYSWm_qQ-euZmX4g/'
      if (this.center == 'Plano') return 'https://order.ubereats.com/dallas/food-delivery/main-event-plano/xQDPS7Z-TmW3wyIW4lRXrA/'
      if (this.center == 'Shenandoah') return 'https://order.ubereats.com/houston/food-delivery/main-event-shenandoah/MAjdPNNcQtaL8ubUcn-I4g/'
      if (this.center == 'Oklahoma City') return 'https://order.ubereats.com/oklahoma-city/food-delivery/main-event-oklahoma-city/J3tPvOlXQyC2so6Ejf5Ixg/'
      if (this.center == 'Olathe') return 'https://order.ubereats.com/kansas-city/food-delivery/main-event-olathe/SYjrF6AdT4KMcdBpw9pigw/'
      if (this.center == 'Webster') return 'https://order.ubereats.com/houston/food-delivery/main-event-webster/dr1BuOdORU-93_zp6jbm2w/'
      if (this.center == 'Katy') return 'https://order.ubereats.com/houston/food-delivery/main-event-katy/WNvtgFO1TGWcEg9CZzIAVQ/'
      if (this.center == 'Tempe') return 'https://order.ubereats.com/phoenix/food-delivery/main-event-tempe/pgnOEc9TQB6M7v21flFLeA/'
      if (this.center == 'Alpharetta') return 'https://order.ubereats.com/atlanta/food-delivery/main-event-alpharetta/N2SNEXTnTcC1wLPkT-RDmA/'
      if (this.center == 'Fort Worth North') return 'https://order.ubereats.com/dallas/food-delivery/main-event-fort-worth-north/wE4VvXjhTV6j-ieeAW-D9w/'
      if (this.center == 'Highlands Ranch') return 'https://order.ubereats.com/denver/food-delivery/main-event-highlands-ranch/PLg8afGGQHG55oKudmgLjQ/'
      if (this.center == 'Kansas City North') return 'https://order.ubereats.com/kansas-city/food-delivery/main-event-kansas-city-north/OU3FMiVmRDuw-T9l2YwC7g/'
      if (this.center == 'Stafford') return 'https://order.ubereats.com/houston/food-delivery/main-event-stafford/272mz2E7TECW0le2z3VMHg/'
      if (this.center == 'Frisco') return 'https://order.ubereats.com/dallas/food-delivery/main-event-frisco/nkzzdsLeQ_Wbki-i0c2ogA/'
      if (this.center == 'Lubbock') return 'https://order.ubereats.com/lubbock/food-delivery/main-event-lubbock/29b2xfOWRC2LICsvQKwyUg/'
      if (this.center == 'Tulsa') return 'https://order.ubereats.com/tulsa/food-delivery/main-event-tulsa/6yRBYMrkRvCaCpmAHDQNmA/'
      if (this.center == 'Knoxville') return 'https://order.ubereats.com/knoxville/food-delivery/main-event-knoxville/SiWPIz9ZSmKbWp73Srb0Wg/'
      if (this.center == 'Gilbert') return 'https://order.ubereats.com/phoenix/food-delivery/main-event-gilbert/B6_qpguBTXeJx-fQCb4juQ/'
      if (this.center == 'San Antonio (West)') return 'https://order.ubereats.com/san-antonio/food-delivery/main-event-san-antonio-west/SYZ4X3mZSoqZBcbQbiqPtw/'
      if (this.center == 'Avondale') return 'https://order.ubereats.com/phoenix/food-delivery/main-event-avondale/Q_41LUlqTBq9uBaCE_QXTQ/'
      if (this.center == 'Suwanee') return 'https://order.ubereats.com/atlanta/food-delivery/main-event-suwanee/MIsY6QtdSQ-c1lUuX1ez4w/'
      if (this.center == 'Austin') return 'https://order.ubereats.com/austin/food-delivery/main-event-austin/XLidwrDfQTSeFWo_VKXOgQ/'
      if (this.center == 'Columbia') return 'https://order.ubereats.com/baltimore/food-delivery/main-event-columbia/KAjvQKjDSLyu3Ozv_miVKg/'
      if (this.center == 'Atlanta') return 'https://order.ubereats.com/atlanta/food-delivery/main-event-atlanta/CzWqSYibS1eqohhMO-I97w/'
      if (this.center == 'Laredo') return 'https://order.ubereats.com/laredo/food-delivery/main-event-laredo/KJth7JkDSd27L7ut5J9nhw/'
      if (this.center == 'Wesley Chapel') return 'https://order.ubereats.com/tampa/food-delivery/Main%20Event%20(Wesley%20Chapel)/3gwOUR_KSFuTtTdoQT4-ww/?utm_source=web-restaurant-manager'
      if (this.center == 'Hoffman Estates') return 'https://order.ubereats.com/chicago/food-delivery/Main%20Event%20(Hoffman%20Estates)/pQt3GJQ8TdassoY2AbngpQ/?utm_source=web-restaurant-manager'
      return 'https://ubereats.com'
    }
  }
}
</script>