<template>
  <div class="teaser" :class="content.cssClass">
    <h2 v-if="content.heading" v-text="content.heading" />
    <BodyCopy class="text-center" v-if="content.body.content" :content="content.body.content" />
    <div v-if="content.cta && content.cta.fields">
      <nuxt-link class="button purple pulse" v-if="content.cta.fields.internalLink" :to="content.cta.fields.url">
        {{ content.cta.fields.title }}
      </nuxt-link>
      <a class="button purple pulse" v-else :href="content.cta.fields.url" target="_blank" >
        {{ content.cta.fields.title }}
      </a>
    </div>
  </div>
</template>

<script>
import BodyCopy from './BodyCopy.vue'

export default {
  props: [
    'content'
  ],
  components: {
    BodyCopy
  }
}
</script>

<style lang="scss" scoped>

@import '~/assets/scss/_variables.scss';

.teaser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  max-width: 800px;
  margin: auto;
  padding: 20px;

  h2 {
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 900;
    color: $purple;
    text-align: center;
  }

  &.red-header {
    h2 {
      color: #d43a5e;
    }
  }
}
</style>