<template>
<div class="p-2 pt-4">
  <div v-for="(opt, inx) in options" :key="inx" :class="`option relative text-sm p-3 ${selected == opt.value ? 'bg-white text-purple border-white' : 'border-purple' } border-2 hover:border-white rounded`" v-on:mouseover="setHoverOption(inx)" v-on:mouseout="setHoverOption(inx)">
    <input
      type="radio"
      class="appearance-none ignore-me block w-full absolute inset-0 cursor-pointer outline-none"
      :id="opt.value"
      :name="name"
      v-on:click="selectOption(opt)" />
    <label class="block ignore-me" :for="opt.value" v-html="opt.title" />
    <div v-if="selected != opt.value && currentOption - 1 != inx && hoverOption - 1 != inx && inx < options.length - 1" class="separator absolute border-b-2 border-white mx-2 inset-x-0 bottom-0 z-10" />
  </div>
</div>
</template>

<script>
export default {
  props: [
    'name',
    'selected',
    'options',
    'handleUpdate',
    'handleClose'
  ],
  data() {
    return {
      hoverOption: null,
      active: false
    }
  },
  computed: {
    currentOption() {
      let index = null
      this.options.map((opt, inx) => {
        if(opt.value == this.selected) {
          index = inx
        }
      })
      return index
    }
  },
  mounted() {
    this.active = true
    setTimeout(this.listenForClose, 500)
  },
  methods: {
    setHoverOption(i) {
      this.hoverOption = i
    },
    selectOption(opt) {
      this.handleUpdate(opt, true)
    },
    listenForClose() {
      if(this.active) {
          document.getElementById('__layout').addEventListener('click', this.closeSelf)
      }
    },
    closeSelf(e) {
      if(e.target.className.indexOf('ignore-me') < 0) {
        this.handleClose(false)
      }
    }
  },
  beforeDestroy() {
    this.active = false
    document.getElementById('__layout').removeEventListener('click', this.closeSelf)
  }
}
</script>

<style lang="scss" scoped>
.option {
  margin-bottom: 2px;

  .separator {
    margin-bottom: -4px;
  }

  &:hover .separator {
    display: none;
  }
}
</style>