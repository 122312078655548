<template>
  <div class="menu-section-media">
    <img :src="content.fields.image.fields.file.url" :alt="content.fields.image.fields.description"/>
    <video controls :poster="(content.fields.video.fields.file.url == '//videos.ctfassets.net/3dar4x4x74wk/2VHlFnWPGW7t6V8gthvNcM/9a93cdd97810ed3d23d7cbeb2ab9b2ba/ME_ShirleyTempleKing_HERO_90s_20210225_SMALL.mp4') ? 'https://images.ctfassets.net/3dar4x4x74wk/65SxFxawludQtl25aKp17J/d24a65c8e0e323b9e87269f3581907e2/120620_MainEvent_KathyTran_B41A9435.jpg' : ''">
      <source :src="content.fields.video.fields.file.url" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  props: [
    'content'
  ]
}
</script>

<style lang="scss">
  @import '../../assets/scss/_variables';

  .menu-section-media {
    width: 1300px;
    max-width: 75vw;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    video {
      height: 23vw;
      object-fit: cover;
    }
    img {
      height: 23vw;
      padding-right: 20px;
      @media (max-width: $sm) {
        margin-bottom: 15px;
      }
    }
    @media (max-width: 1220px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 100vw;
    }
    @media (max-width: $sm) {
      justify-content: center;
      padding: 0 18px;
      img, video {
        padding: 0;
        width: 100%;
        height: auto;
      }
    }
  }
</style>