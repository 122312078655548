<template>
  <div class="menu-item" :class="customClass">
    <div class="flex">
      <div class="menu-item-title">{{title}}</div>
      <div class="menu-item-calories" v-if="calories">{{calories}}</div>
      <div class="menu-item-price" v-if="price">{{price}}</div>
    </div>
    <div class="menu-item-description" v-if="description">{{description}}</div>
    <div class="flex" v-for="(item, index) in items" :key="index">
      <div v-if="item.fields.title" class="menu-item-subitem">{{item.fields.title}}</div>
      <div v-if="item.fields.price" class="menu-item-price">{{item.fields.price}}</div>
      <div v-if="item.fields.calories" class="menu-item-calories">{{item.fields.calories}}</div>
    </div>
    <div class="menu-item-extra" v-if="extra && extra.title">
      <div class="menu-item-extra-title">{{extra.title}}</div>
      <div class="menu-item-extra-description">{{extra.description}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    price: String,
    calories: String,
    description: String,
    extra: Object,
    customClass: String,
    items: Array,
  }
}
</script>